//
// SubscriptionStatusBanner.tsx
//

import { useViewSubscription } from "@custom-hooks/billing";
import { SubscriptionStatusAlertProps } from "@data-types/billing-types";
import { ContactDialog } from "@tw-components/support/ContactDialog";
import { Button } from "@tw-components/ui/iso";
import { useState } from "react";
import { ViewStripeBillingPortalButtonWithPermissionsGuard } from "./ViewStripeBillingPortalButtonWithPermissionsGuard";

/**
 * A banner component that notifies the user when there is an issue with their subscription payment.
 *
 * @param {SubscriptionStatusAlertProps} props - The props for the component.
 *
 * @returns {JSX.Element} The `SubscriptionStatusBanner` component with an action button.
 */
export default function SubscriptionStatusBanner({
  title,
  description,
  actionButtonText
}: SubscriptionStatusAlertProps): JSX.Element {
  const [showContactDialog, setShowContactDialog] = useState(false);
  const {
    viewSubscription,
    isViewSubscriptionLoading
  } = useViewSubscription({});
  return <>
      <div className="tw-mx-auto tw-w-full tw-max-w-[109rem] tw-rounded-2xl tw-bg-semantics-error10-light tw-p-6 dark:tw-bg-semantics-error10-dark">
        <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
          <div className="tw-flex tw-flex-col tw-gap-y-2">
            <div className="tw-flex tw-flex-row tw-items-center tw-gap-x-3">
              <div className="tw-text-h5-mob tw-text-semantics-error-light sm:tw-text-h5-desk dark:tw-text-semantics-error-dark">
                {title}
              </div>
            </div>
            <div className="tw-dark:tw-text-text-subTitle-dark tw-text-14px-regular tw-text-text-subTitle-light">
              {description}
            </div>
          </div>
          <div className="tw-flex tw-flex-col tw-items-center tw-gap-3 sm:tw-flex-row">
            <ViewStripeBillingPortalButtonWithPermissionsGuard onClick={viewSubscription} variant="primary" label={actionButtonText} showLoader={isViewSubscriptionLoading} className="tw-w-48" shouldHideButtonIfNoPermission={false} data-sentry-element="ViewStripeBillingPortalButtonWithPermissionsGuard" data-sentry-source-file="SubscriptionStatusBanner.tsx" />

            <Button onClick={() => setShowContactDialog(true)} variant="primary" label="Contact Us" className="tw-w-32" data-sentry-element="Button" data-sentry-source-file="SubscriptionStatusBanner.tsx" />
          </div>
        </div>
      </div>

      <ContactDialog isOpen={showContactDialog} onClose={() => setShowContactDialog(false)} source={"Subscription Status Banner"} data-sentry-element="ContactDialog" data-sentry-source-file="SubscriptionStatusBanner.tsx" />
    </>;
}