import { withPermissionsGuard } from "@hoc/withPermissionsGuard";
import LeaveIcon from "@layouts/svg-icon/leave-icon.svg";
import { Button, StandardButtonPropsCustomVariant } from "@tw-components/ui/iso";

/**
 * Props for the LeaveOrganizationButton component.
 */
type LeaveOrganizationButtonProps = Omit<StandardButtonPropsCustomVariant, "variant" | "customStyles" | "label">;

/**
 * A button that allows the user to open the Delete Organization Dialog.
 *
 * @param {LeaveOrganizationButtonProps} props - The props for the button component.
 *
 * @returns {JSX.Element} The JSX code for rendering the button.
 */
function LeaveOrganizationButton(props: LeaveOrganizationButtonProps): JSX.Element {
  return <Button variant="custom" customStyles={{
    base: ["tw-flex tw-flex-row tw-gap-2 tw-text-13px-medium"],
    enabled: ["tw-text-semantics-error-light dark:tw-text-semantics-error-dark"],
    disabled: ["tw-text-interface-gray-light dark:tw-text-interface-gray-dark"]
  }} {...props} data-sentry-element="Button" data-sentry-component="LeaveOrganizationButton" data-sentry-source-file="LeaveOrganizationButtonWithPermissionsGuard.tsx">
      <LeaveIcon data-sentry-element="LeaveIcon" data-sentry-source-file="LeaveOrganizationButtonWithPermissionsGuard.tsx" />

      <span>Leave workspace</span>
    </Button>;
}

/**
 * A higher-order component that wraps the LeaveOrganizationButton with permission checks.
 * If the user lacks the required permissions, the button is not rendered.
 *
 * @param {LeaveOrganizationButtonProps} props - The props for the button component.
 *
 * @returns {JSX.Element} The JSX code for rendering the button with permissions guard.
 */
export const LeaveOrganizationButtonWithPermissionsGuard = withPermissionsGuard({
  Component: LeaveOrganizationButton,
  permissions: ["canLeaveOrganization"]
});