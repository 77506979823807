//
// PlanCard.tsx
//

import * as Headless from "@headlessui/react";
import CheckmarkIcon from "@layouts/svg-icon/checkmark-icon.svg";
import TooltipIcon from "@layouts/svg-icon/help-question-mark-icon.svg";
import clsx from "clsx";

/**
 * The props of the PlanCard component.
 *
 * @property {string} title - The title of the plan card.
 * @property {Array<{ summary?: string, tooltip?: string }>} details - Array of details that include a summary and tooltip.
 * @property {string} [classname] - Class name to customize the background styling of the plan card.
 */
export type PlanCardProps = {
  title: string;
  details: {
    summary?: string | React.ReactNode;
    tooltip?: string;
  }[];
  classname?: string;
};

/**
 * PlanCard component that displays a card with a title, details, and optional tooltips.
 *
 * @param {PlanCardProps} props - The props for the PlanCard component.
 *
 * @returns {JSX.Element} The rendered PlanCard component.
 */
export function PlanCard({
  title,
  details,
  classname
}: PlanCardProps): JSX.Element {
  return <div className={clsx(classname, "tw-flex tw-flex-col tw-justify-between tw-gap-[1.125rem] tw-rounded-2xl tw-border tw-border-interface-divider-light tw-p-4 dark:tw-border-interface-divider-dark")} data-sentry-component="PlanCard" data-sentry-source-file="PlanCard.tsx">
      <div className="tw-text-18px-semiBold tw-text-brandBlues-brandDark-light dark:tw-text-brandBlues-brandDark-dark">
        {title}
      </div>

      <div className="tw-flex tw-flex-col tw-gap-5">
        {details.filter(detail => detail.summary !== undefined).map(detail => <>
              <div className="tw-flex tw-flex-row tw-items-center">
                <div className="tw-text-brandBlues-brandSecondary-light dark:tw-text-brandBlues-brandSecondary-dark">
                  <CheckmarkIcon />
                </div>

                <div className="tw-ml-1.5 tw-mr-1 tw-text-12px-regular tw-text-text-subTitle-light dark:tw-text-text-subTitle-dark">
                  {detail.summary}
                </div>

                {detail.tooltip && <Headless.Button>
                    {({
              hover
            }) => <span className="tw-relative">
                        <TooltipIcon />

                        {hover && <span className={clsx("tw-absolute tw-left-1/2 tw-top-full tw-mt-[6px] -tw-translate-x-1/2 tw-transform", "tw-w-32 tw-rounded-lg tw-p-2", "tw-text-start tw-text-10px-regular tw-text-text-subTitle-light dark:tw-text-text-subTitle-dark", "tw-border tw-border-interface-divider-light dark:tw-border-interface-divider-dark", "tw-bg-interface-card-light dark:tw-bg-interface-card-dark")}>
                            {detail.tooltip}
                          </span>}
                      </span>}
                  </Headless.Button>}
              </div>
            </>)}
      </div>
    </div>;
}