import CopyIcon from "@layouts/svg-icon/copy-icon.svg";
import clsx from "clsx";
import { toJsxRuntime } from "hast-util-to-jsx-runtime";
import { Fragment, useEffect, useState } from "react";
import { jsx, jsxs } from "react/jsx-runtime";
import { createHighlighterCoreSync, createJavaScriptRegexEngine, HighlighterCore } from "shiki";
import go from "shiki/langs/go.mjs";
import js from "shiki/langs/javascript.mjs";
import php from "shiki/langs/php.mjs";
import python from "shiki/langs/python.mjs";
import swift from "shiki/langs/swift.mjs";
import ts from "shiki/langs/typescript.mjs";
import githubDark from "shiki/themes/github-dark.mjs";
import { Button, Listbox, ListboxLabel, ListboxOption } from "../iso";

// Declare a variable to hold the singleton instance of the highlighter
let shikiHighlighter: HighlighterCore | null = null;

/**
 * Returns a singleton instance of the Shiki highlighter.
 * Initializes the highlighter only once to avoid unnecessary re-initialization during re-rendering.
 *
 * @returns {HighlighterCore} The Shiki highlighter instance.
 */
function getShikiHighlighter() {
  if (!shikiHighlighter) {
    shikiHighlighter = createHighlighterCoreSync({
      themes: [githubDark],
      langs: [js, ts, python, go, php, swift],
      // Swift is not supported by the JavaScript RegExp engine
      // we use `forgiving: true` to skip any possible parsing errors.
      engine: createJavaScriptRegexEngine({
        forgiving: true
      })
    });
  }
  return shikiHighlighter;
}

/**
 * Represents a single code snippet option.
 *
 * @property {string} label - A label describing the code snippet.
 * @property {string} code - The complete code snippet as a single string.
 * @property {string} language - The programming language of the snippet for syntax highlighting.
 */
export type CodeSnippet = {
  label: string;
  code: string;
  language: string;
};

/**
 * Props for the `CodeSnippetViewer` component, supporting both single and multiple code snippet variants.
 *
 * @property {"bottom" | "topRightOutside" | "topRightInside" | "none"} copyButtonPosition -
 *   The position of the copy button relative to the code snippet display.
 * @property {string} [className] - Additional class names applied to the outermost container of the component.
 * @property {"singleSnippet" | "multiSnippet"} variant -
 *   Determines the mode of the component:
 *   - "singleSnippet": Display a single code snippet.
 *   - "multiSnippet": Display multiple code snippets with a dropdown to switch between variants.
 * @property {Record<string, CodeSnippet> | CodeSnippet} codeSnippet -
 *   The code snippet(s) to be displayed.
 *   - If a `Record<string, CodeSnippet>` is provided, multiple variants are supported.
 *   - If a single `CodeSnippet` is provided, the component renders without variant switching.
 * @property {string} [defaultSnippetKey] -
 *   The key of the default code variant to display. Required when `variant` is multiSnippet.
 *   Must be omitted when `variant` is singleSnippet.
 */
export type CodeSnippetViewerProps = {
  copyButtonPosition: "bottom" | "topRightOutside" | "topRightInside" | "none";
  className?: string;
} & ({
  variant: "multiSnippet";
  codeSnippet: Record<string, CodeSnippet>;
  defaultSnippetKey: string;
} | {
  variant: "singleSnippet";
  codeSnippet: CodeSnippet;
  defaultSnippetKey?: never;
});

/**
 * A React component for displaying code snippets with syntax highlighting,
 * copy functionality, and support for multiple code variants.
 *
 * @param {CodeSnippetViewerProps} props - The props for the `CodeSnippetViewer` component.
 *
 * @returns {JSX.Element} A styled code snippet display with optional selector for code variants and a copy button.
 */
export function CodeSnippetViewer({
  copyButtonPosition,
  variant,
  codeSnippet,
  defaultSnippetKey,
  className
}: CodeSnippetViewerProps) {
  const [selectedCodeSnippet, setSelectedCodeSnippet] = useState(variant === "singleSnippet" ? codeSnippet : codeSnippet[defaultSnippetKey]);
  const [highlightedCode, setHighlightedCode] = useState<JSX.Element | undefined>(undefined);
  const [copied, setCopied] = useState(false);
  const shiki = getShikiHighlighter();
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(selectedCodeSnippet.code);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    } catch (err) {}
  };
  const updateHighlightedCode = (codeSnippet: CodeSnippet) => {
    const hast = shiki.codeToHast(codeSnippet.code, {
      lang: codeSnippet.language,
      theme: "github-dark",
      colorReplacements: {
        "#24292e": "#0d0c22"
      }
    });
    const out = toJsxRuntime(hast, {
      Fragment,
      jsx,
      jsxs
    });
    setHighlightedCode(out);
  };
  const copyButton = <Button variant="primary" label={copied ? "Copied!" : "Copy"} size="large" icon={!copied && <CopyIcon />} className="tw-text-brandBlues-pale-light [--btn-bg:theme(colors.brandBlues.darkBlue.light)] [--btn-border:theme(colors.brandBlues.darkBlue.light)] [--btn-icon:theme(colors.interface.card.light)]" onClick={copyToClipboard} />;
  useEffect(() => {
    updateHighlightedCode(selectedCodeSnippet);
  }, [selectedCodeSnippet]);
  useEffect(() => {
    setSelectedCodeSnippet(variant === "singleSnippet" ? codeSnippet : codeSnippet[defaultSnippetKey]);
  }, [codeSnippet]);
  return <div className={clsx(className, "tw-flex tw-flex-col tw-rounded-lg tw-bg-brandBlues-darkBlue-light")} data-sentry-component="CodeSnippetViewer" data-sentry-source-file="CodeSnippetViewer.tsx">
      {(variant === "multiSnippet" || copyButtonPosition === "topRightOutside") && <div className="tw-flex tw-w-full tw-flex-row tw-justify-between tw-border-b tw-border-text-subTitle-light">
          {variant === "multiSnippet" && <div className="tw-inline-flex tw-border-r tw-border-text-subTitle-light tw-pl-1 tw-pt-1">
              <Listbox size="small" defaultValue={defaultSnippetKey} style="custom" customStyle={{
          typography: {
            placeholder: [],
            button: ["tw-text-13px-medium", "tw-text-brandBlues-ice-light"],
            selectedOption: ["tw-text-12px-regular", "tw-text-brandBlues-ice-light"],
            unselectedOption: ["tw-text-12px-regular", "tw-text-interface-divider-light data-[focus]:tw-opacity-60"]
          },
          border: {
            button: [],
            buttonOpen: [],
            selectedOption: [],
            options: ["tw-border tw-border-text-subTitle-light"]
          },
          background: {
            button: ["tw-bg-brandBlues-darkBlue-light"],
            selectedOption: ["tw-bg-brandBlues-darkBlue-light"],
            options: ["tw-bg-brandBlues-darkBlue-light"]
          },
          icon: {
            enabled: ["tw-text-brandBlues-ice-light dark:tw-text-brandBlues-ice-light"],
            disabled: []
          }
        }} className="tw-min-w-28" onChange={(key: string) => {
          setSelectedCodeSnippet(codeSnippet[key]);
        }}>
                {Object.entries(codeSnippet).map(([key, snippet]) => <ListboxOption key={key} value={key}>
                    <ListboxLabel>{snippet.label}</ListboxLabel>
                  </ListboxOption>)}
              </Listbox>
            </div>}

          {copyButtonPosition === "topRightOutside" && <div className="tw-border-l tw-border-text-subTitle-light">
              {copyButton}
            </div>}
        </div>}

      <div className="[&::-webkit-scrollbar]:hidden tw-relative tw-flex-1 tw-overflow-y-auto tw-p-4 [-ms-overflow-style:none] [scrollbar-width:none]">
        {highlightedCode && highlightedCode}
        {copyButtonPosition === "topRightInside" && <div className="tw-absolute tw-right-0 tw-top-0 tw-p-2">
            {copyButton}
          </div>}
      </div>

      {copyButtonPosition === "bottom" && <div className="tw-w-full tw-border-t tw-border-text-subTitle-light tw-p-1">
          {copyButton}
        </div>}
    </div>;
}