//
// useVerifyInvitation.ts
//

import { useEditData_v2 } from "@custom-hooks/edit-data";
import { EditDataOpt } from "@data-types/generic-hook-type";
import { VerifyInvitationIdResponse } from "@data-types/members-types";

export const useVerifyInvitation = (
  options: EditDataOpt<VerifyInvitationIdResponse>
) => {
  const { isLoading, isMutatingData, editData, error } =
    useEditData_v2<VerifyInvitationIdResponse>({
      ...options,
      stopGlobalError: options?.stopGlobalError ?? true,
    });

  const verifyInvitation = async (invitationId: string) => {
    return await editData({
      method: "GET",
      endpoint: `/api/invitations/verify/${invitationId}`,
      endpointCallLocation: "useVerifyInvitation.js",
      serverType: "dashboard",
      mutateApis: [],
    });
  };

  return {
    verifyInvitation,
    isVerifyingInvitation: isLoading || isMutatingData,
    errorVerifyingInvitation: error,
  };
};
