"use client";

//
// AppNavbar.tsx
//
import { Search } from "@components/Search/Search";
import Profile from "@components/User/Profile";
import { useGetUserSession } from "@custom-hooks/user";
import OpenDialog from "@generic-components/OpenDialog";
import { CircleBrandMark } from "@layouts/ApplicationLayout/CircleBrandMark";
import BinocularsIcon from "@layouts/svg-icon/binoculars-icon.svg";
import CommunityIcon from "@layouts/svg-icon/community-icon.svg";
import DownChevronIcon from "@layouts/svg-icon/down-chevron-icon.svg";
import LogoutIcon from "@layouts/svg-icon/logout-icon.svg";
import MessageSquareTypingIcon from "@layouts/svg-icon/message-square-typing-icon.svg";
import MobileHamburgerIcon from "@layouts/svg-icon/mobile-hamburger-icon.svg";
import QuestionMarkIcon from "@layouts/svg-icon/question-mark-icon.svg";
import UserProfileCircleIcon from "@layouts/svg-icon/user-profile-circle-icon.svg";
import WarningIcon from "@layouts/svg-icon/warning-icon.svg";
import { signOut } from "@lib/client-side";
import { getInitials } from "@lib/iso-utils";
import { useAppState } from "@state/AppStateProvider";
import { BreadcrumbNavigation } from "@tw-components/common/BreadcrumbNavigation";
import { UpgradingInfraDialog } from "@tw-components/common/UpgradingInfraDialog";
import { Avatar, Button, Dropdown, DropdownButton, DropdownItem, DropdownLabel, DropdownMenu, Navbar, NavbarItem, NavbarSection, NavbarSpacer } from "@tw-components/ui/iso";
import Link from "next/link";
import { MutableRefObject, useState } from "react";
const UPGRADING_INFRA = process.env.NEXT_PUBLIC_UPGRADING_INFRA;
export default function AppNavbar({
  setShowMobileSidebar,
  onContactUsButtonClicked,
  profileRefDialog
}: {
  setShowMobileSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  onContactUsButtonClicked: () => void;
  profileRefDialog: MutableRefObject<any | null>;
}) {
  // Get user session
  const {
    data: session
  } = useGetUserSession();
  const userName = session?.user?.name ?? "";

  // Get simulation generic user from context
  const {
    simulateGenericUser
  } = useAppState();

  // Render condition
  const upgradingInfra = UPGRADING_INFRA && UPGRADING_INFRA.toLowerCase() === "true";
  const [showUpgradingInfraDialog, setShowUpgradingInfraDialog] = useState(false);

  // Render component
  return <>
      {/* Profile Dialog Box */}
      {/* @ts-expect-error OpenDialog method not typed for TypeScript*/}
      <OpenDialog ref={profileRefDialog} style="none" size="small-modal" data-sentry-element="OpenDialog" data-sentry-source-file="AppNavbar.tsx">
        <Profile data-sentry-element="Profile" data-sentry-source-file="AppNavbar.tsx" />
      </OpenDialog>

      {/* Upgrading Infra Dialog */}
      <UpgradingInfraDialog isOpen={showUpgradingInfraDialog} onClose={() => setShowUpgradingInfraDialog(false)} type={"generic"} data-sentry-element="UpgradingInfraDialog" data-sentry-source-file="AppNavbar.tsx" />

      {/* Navigation Bar */}
      <Navbar data-sentry-element="Navbar" data-sentry-source-file="AppNavbar.tsx">
        {/* Brand Mark and Desktop Breadcrumbs */}
        <div className="tw-flex tw-flex-col tw-items-start tw-gap-x-8 tw-gap-y-12 lg:tw-flex-row lg:tw-items-center">
          <div className="tw-block lg:tw-hidden">
            <Link href="/" data-sentry-element="Link" data-sentry-source-file="AppNavbar.tsx">
              <CircleBrandMark data-sentry-element="CircleBrandMark" data-sentry-source-file="AppNavbar.tsx" />
            </Link>
          </div>
          <div className="tw-hidden lg:tw-block">
            <BreadcrumbNavigation data-sentry-element="BreadcrumbNavigation" data-sentry-source-file="AppNavbar.tsx" />
          </div>
        </div>
        <NavbarSpacer data-sentry-element="NavbarSpacer" data-sentry-source-file="AppNavbar.tsx" />
        {/* NavBar items */}
        <NavbarSection data-sentry-element="NavbarSection" data-sentry-source-file="AppNavbar.tsx">
          {upgradingInfra && <Button className="tw-mx-2" onClick={() => setShowUpgradingInfraDialog(true)} variant="tertiary" label="Upgrading" size="medium" icon={<WarningIcon />} />}
          {!simulateGenericUser && <Button variant="destructive" label="Experimental" size="medium" className="tw-pointer-events-none tw-mx-2" icon={<BinocularsIcon />} />}
          <span className="tw-flex tw-items-center tw-justify-center tw-px-2">
            <Search style="only-icon" targetLink="_blank" relativeUrl={false} hiddenClass="opacity-0" searchSource="doc" modalInputPlaceholder="Search SQLite Cloud" data-sentry-element="Search" data-sentry-source-file="AppNavbar.tsx" />
          </span>
          {/* Support Dropdown menu */}
          <Dropdown data-sentry-element="Dropdown" data-sentry-source-file="AppNavbar.tsx">
            <DropdownButton as={NavbarItem} className="max-lg:tw-hidden" aria-label="Support Menu" data-sentry-element="DropdownButton" data-sentry-source-file="AppNavbar.tsx">
              <QuestionMarkIcon data-sentry-element="QuestionMarkIcon" data-sentry-source-file="AppNavbar.tsx" />
              <DownChevronIcon data-sentry-element="DownChevronIcon" data-sentry-source-file="AppNavbar.tsx" />
            </DropdownButton>
            <DropdownMenu className="min-w-64" anchor="bottom end" data-sentry-element="DropdownMenu" data-sentry-source-file="AppNavbar.tsx">
              <DropdownItem href={"https://github.com/orgs/sqlitecloud/discussions/"} target="_blank" data-sentry-element="DropdownItem" data-sentry-source-file="AppNavbar.tsx">
                <CommunityIcon data-sentry-element="CommunityIcon" data-sentry-source-file="AppNavbar.tsx" />
                <DropdownLabel data-sentry-element="DropdownLabel" data-sentry-source-file="AppNavbar.tsx">Community</DropdownLabel>
              </DropdownItem>
              <DropdownItem onClick={onContactUsButtonClicked} data-sentry-element="DropdownItem" data-sentry-source-file="AppNavbar.tsx">
                <MessageSquareTypingIcon data-sentry-element="MessageSquareTypingIcon" data-sentry-source-file="AppNavbar.tsx" />
                <DropdownLabel data-sentry-element="DropdownLabel" data-sentry-source-file="AppNavbar.tsx">Contact Us</DropdownLabel>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>

          {/* Profile Dropdown menu */}
          <Dropdown data-sentry-element="Dropdown" data-sentry-source-file="AppNavbar.tsx">
            <DropdownButton as={NavbarItem} className="max-lg:tw-hidden" aria-label="Profile Menu" data-sentry-element="DropdownButton" data-sentry-source-file="AppNavbar.tsx">
              <Avatar initials={getInitials(userName)} data-sentry-element="Avatar" data-sentry-source-file="AppNavbar.tsx" />
            </DropdownButton>
            <DropdownMenu className="min-w-64" anchor="bottom end" data-sentry-element="DropdownMenu" data-sentry-source-file="AppNavbar.tsx">
              <DropdownItem onClick={() => {
              profileRefDialog.current?.open();
            }} data-sentry-element="DropdownItem" data-sentry-source-file="AppNavbar.tsx">
                <UserProfileCircleIcon data-sentry-element="UserProfileCircleIcon" data-sentry-source-file="AppNavbar.tsx" />
                <DropdownLabel data-sentry-element="DropdownLabel" data-sentry-source-file="AppNavbar.tsx">Profile</DropdownLabel>
              </DropdownItem>
              <DropdownItem onClick={async () => {
              signOut();
            }} data-sentry-element="DropdownItem" data-sentry-source-file="AppNavbar.tsx">
                <LogoutIcon data-sentry-element="LogoutIcon" data-sentry-source-file="AppNavbar.tsx" />
                <DropdownLabel data-sentry-element="DropdownLabel" data-sentry-source-file="AppNavbar.tsx">Sign out</DropdownLabel>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <NavbarItem className="tw-block lg:tw-hidden" onClick={() => {
          setShowMobileSidebar(true);
        }} data-sentry-element="NavbarItem" data-sentry-source-file="AppNavbar.tsx">
            <MobileHamburgerIcon className="!tw-h-10 !tw-w-10" data-sentry-element="MobileHamburgerIcon" data-sentry-source-file="AppNavbar.tsx" />
          </NavbarItem>
        </NavbarSection>
      </Navbar>
      {/* Breadcrumbs mobile */}
      <div className="tw-mb-11 tw-mt-9 tw-block tw-px-4 lg:tw-hidden">
        <BreadcrumbNavigation data-sentry-element="BreadcrumbNavigation" data-sentry-source-file="AppNavbar.tsx" />
      </div>
    </>;
}