import { useEditData_v2 } from "@custom-hooks/edit-data";
import { ApiKey } from "@data-types/api-key-types";
import { EditDataOpt, EditDataResult } from "@data-types/generic-hook-type";

/**
 * Custom hook that handles the deletion of an API key for a specific project.
 *
 * @param {EditDataOpt<null>} options - Configuration options for the `useEditData_v2` hook.
 *
 * @returns An object containing:
 * - `deleteApiKey`: A function to delete the specified API key.
 * - `isDeletingApiKey`: A boolean indicating if the request is in progress.
 * - `error`: Any error encountered during the request, if any.
 */
export function useDeleteApiKey(options: EditDataOpt<null>) {
  const { isMutatingData, isLoading, editData, error } = useEditData_v2({
    ...options,
    stopGlobalError: options?.stopGlobalError ?? true,
  });

  /**
   * Function to delete an API key for a specific project.
   *
   * @param {string} params.projectId - The ID of the project from which the API key will be deleted.
   * @param {ApiKey} params.apiKey - The API key to be deleted. The `key` field of this object will be sent in the request.
   *
   * @returns {Promise<EditDataResult<null>>} A promise that resolves to the result of the API key deletion operation, either success or failure.
   */
  const deleteApiKey = async ({
    projectId,
    apiKey,
  }: {
    projectId: string;
    apiKey: ApiKey;
  }): Promise<EditDataResult<null>> => {
    return await editData({
      method: "POST",
      endpoint: `/api/projects/${projectId}/apikey/delete`,
      endpointCallLocation: "useDeleteApiKey",
      serverType: "backend",
      body: {
        key: apiKey.key,
      },
      mutateApis: [
        {
          url: `/api/projects/${projectId}/apikey`,
          component: "useGetProjectApiKey",
        },
      ],
    });
  };

  return {
    deleteApiKey,
    isDeletingApiKey: isLoading || isMutatingData,
    error,
  };
}
