///
// useInviteFreeMember.ts
///

import { useEditData_v2 } from "@custom-hooks/edit-data";
import { EditDataOpt, EditDataResult } from "@data-types/generic-hook-type";
import { CreateInvitationInput, Invitation } from "@data-types/members-types";

/**
 * Custom hook for inviting free member to an organization.
 *
 * @param {EditDataOpt<Invitation>} options - Options for the hook including mutation configurations.
 *
 * @returns {Function} return.inviteFreeMember - Function to invite free member.
 * @returns {boolean} return.isInvitingMembers - Loading state of the invitations.
 * @returns {Error | null} return.error - Error state, if any.
 */
export function useInviteFreeMember(options: EditDataOpt<Invitation>) {
  const { isMutatingData, isLoading, editData, error } = useEditData_v2({
    ...options,
    stopGlobalError: options?.stopGlobalError ?? true,
  });

  /**
   * Invites a free member to an organization.
   *
   * @param {CreateInvitationInput} input - Invitation details.
   *
   * @returns {Promise<EditDataResult<Invitation>>} - Result of the invitation request.
   */
  const inviteFreeMember = async ({
    organizationId,
    ...body
  }: CreateInvitationInput & { organizationId: string }): Promise<
    EditDataResult<Invitation>
  > => {
    return await editData({
      method: "POST",
      endpoint: `/api/organizations/${organizationId}/invitations`,
      endpointCallLocation: "useInviteFreeMember",
      serverType: "dashboard",
      body: body,
      mutateApis: [
        {
          url: `/api/organizations/${organizationId}/invitations`,
          component: "useGetPendingInvitations",
        },
      ],
    });
  };

  return {
    inviteFreeMember,
    isInvitingFreeMember: isLoading || isMutatingData,
    errorInvitingFreeMember: error,
  };
}
