import { useViewSubscription } from "@custom-hooks/billing";
import { useUserPermissions } from "@custom-hooks/role-based-access-control";
import { SubscriptionStatusAlertProps } from "@data-types/billing-types";
import { FAILED_TO_VIEW_SUBSCRIPTION_ERROR_MESSAGE } from "@lib/billing/constants";
import { ContactDialog } from "@tw-components/support/ContactDialog";
import { Alerts, GenericDialog, Message, MessageType } from "@tw-components/ui/iso";
import { useState } from "react";

/**
 * Props for the SubscriptionStatusDialog component.
 *
 * @property {string} title - The title of the dialog.
 * @property {string} description - The description to be displayed in the alert.
 * @property {string} actionButtonText - The label for the primary action button.
 * @property {boolean} isOpen - Controls whether the dialog is open.
 * @property {() => void} onClose - Callback function invoked when the dialog is closed.
 */
type SubscriptionStatusDialogProps = SubscriptionStatusAlertProps & {
  isOpen: boolean;
  onClose: () => void;
};

/**
 * Displays a dialog to show subscription status with options to view subscription
 * or contact support.
 *
 * @param {SubscriptionStatusDialogProps} props - Component props.
 *
 * @returns {JSX.Element} The rendered SubscriptionStatusDialog component.
 */
export function SubscriptionStatusDialog({
  title,
  description,
  actionButtonText,
  isOpen,
  onClose
}: SubscriptionStatusDialogProps): JSX.Element {
  const [showContactDialog, setShowContactDialog] = useState(false);
  const [alertMessage, setAlertMessage] = useState<Message | undefined>(undefined);
  const {
    viewSubscription,
    isViewSubscriptionLoading,
    viewSubscriptionError
  } = useViewSubscription({
    errorCallback: () => {
      setAlertMessage(FAILED_TO_VIEW_SUBSCRIPTION_ERROR_MESSAGE);
    },
    stopGlobalError: true
  });
  const {
    canUpdateBillingDetails
  } = useUserPermissions();
  return <>
      <GenericDialog onClose={onClose} open={isOpen} title={title} body={<Alerts messages={[{
      type: MessageType.Error,
      title: description
    }]} />} submitButtonProps={{
      variant: "primary",
      label: actionButtonText,
      showLoader: isViewSubscriptionLoading,
      className: "tw-w-52",
      onClick: viewSubscription,
      tooltipOnHover: canUpdateBillingDetails ? undefined : {
        shouldDisableButton: true,
        text: "You don't have permissions to update billing details.",
        alignment: "topRight"
      }
    }} secondaryButtonProps={{
      text: <p className="tw-underline">Contact us</p>,
      onClick: () => {
        onClose();
        setShowContactDialog(true);
      }
    }} alertMessage={alertMessage} size="xl" data-sentry-element="GenericDialog" data-sentry-source-file="SubscriptionStatusDialog.tsx" />

      <ContactDialog isOpen={showContactDialog} onClose={() => setShowContactDialog(false)} error={viewSubscriptionError} source={"Subscription Status Dialog"} data-sentry-element="ContactDialog" data-sentry-source-file="SubscriptionStatusDialog.tsx" />
    </>;
}