///
// useCancelInvitation.ts
///

import { useEditData_v2 } from "@custom-hooks/edit-data";
import { EditDataOpt, EditDataResult } from "@data-types/generic-hook-type";

/**
 * Custom hook for canceling invitations to users.
 * Handles the API call to revoke an existing invitation.
 *
 * @param {EditDataOpt<null>} options - Options for the hook including mutation configurations.
 *
 * @returns {Function} return.cancelInvitation - Function to cancel an invitation.
 * @returns {boolean} return.isCancellingInvitation - Loading state of the cancellation operation.
 * @returns {Error | null} return.errorCancellingInvitation - Error state, if any.
 */
export function useCancelInvitation(options: EditDataOpt<null>) {
  const { isMutatingData, isLoading, editData, error } = useEditData_v2({
    ...options,
    stopGlobalError: options?.stopGlobalError ?? true,
  });

  /**
   * Cancels an invitation to a user.
   *
   * @param {string} params.organizationId - The ID of the organization.
   * @param {string} params.invitationId - The ID of the invitation.
   *
   * @returns {Promise<EditDataResult<null>>} - The result of the cancellation request.
   */
  const cancelInvitation = async ({
    organizationId,
    invitationId,
  }: {
    organizationId: string;
    invitationId: string;
  }): Promise<EditDataResult<null>> => {
    return await editData({
      method: "DELETE",
      endpoint: `/api/organizations/${organizationId}/invitations/${invitationId}`,
      endpointCallLocation: "useCancelInvitation",
      serverType: "dashboard",
      mutateApis: [
        {
          url: `/api/organizations/${organizationId}/invitations`,
          component: "useGetPendingInvitations",
        },
      ],
    });
  };

  return {
    cancelInvitation,
    isCancellingInvitation: isLoading || isMutatingData,
    errorCancellingInvitation: error,
  };
}
