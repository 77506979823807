///
// useRemoveMember.ts
///

import { useEditData_v2 } from "@custom-hooks/edit-data";
import { EditDataOpt, EditDataResult } from "@data-types/generic-hook-type";
import { Session } from "@data-types/user-types";
import { users_removeOrganizationIdFromSession } from "@lib/users-database/iso";

/**
 * Custom hook for removing a member from an organization.
 * Handles the API call to remove a specified user.
 *
 * @param {EditDataOpt<null>} options - Options for the hook including mutation configurations.
 *
 * @returns {Function} return.removeMember - Function to remove a member.
 * @returns {boolean} return.isRemovingMember - Loading state of the remove operation.
 * @returns {Error | null} return.errorRemovingMember - Error state, if any.
 */
export function useRemoveMember(options: EditDataOpt<null>) {
  const { isMutatingData, isLoading, editData, error } = useEditData_v2({
    ...options,
    stopGlobalError: options?.stopGlobalError ?? true,
  });

  /**
   * Removes a member from an organization.
   *
   * @param {string} params.organizationId - The ID of the organization.
   * @param {string} params.userId - The ID of the user to be removed.
   * @param {boolean} params.isCurrentUser - True if user id being removed is the same as the current user.
   *
   * @returns {Promise<EditDataResult<null>>} - The result of the remove request.
   */
  const removeMember = async ({
    organizationId,
    userId,
    isCurrentUser,
    session,
  }: {
    organizationId: string;
    userId: string;
    isCurrentUser: boolean;
    session: Session | undefined;
  }): Promise<EditDataResult<null>> => {
    const optimisticSession = users_removeOrganizationIdFromSession(
      session,
      organizationId
    );

    return await editData({
      method: "DELETE",
      endpoint: `/api/organizations/${organizationId}/members/${userId}`,
      endpointCallLocation: "useRemoveMember",
      serverType: "dashboard",
      mutateApis: [
        isCurrentUser
          ? {
              key: { url: "/api/auth/session", component: "useGetUserSession" },
              mutateOptimisticData: optimisticSession,
            }
          : {
              url: `/api/organizations/${organizationId}/members`,
              component: "useGetMembers",
            },
      ],
    });
  };

  return {
    removeMember,
    isRemovingMember: isLoading || isMutatingData,
    errorRemovingMember: error,
  };
}
