//
// validateUserPermission.ts
//

import { Permission } from "@data-types/role-based-access-control-types";
import { Authorizations } from "@data-types/user-types";
import { throwIsomorphicError } from "@lib/iso-utils";

/**
 * Validates whether the user has all the required permissions to access a specific resource.
 *
 * @param resources - A record of resources (organizations or projects) mapped by their IDs.
 * @param requiredPermissions - An array of permissions that the user must have.
 * @param resourceId - The ID of the resource being accessed.
 * @returns `true` if the user has all required permissions; otherwise, throws an error.
 *
 * @throws {Error} Throws a `Forbidden` error if the resource does not exist or if the user lacks the required permissions.
 */
export function validateUserPermission(
  resources: Authorizations["organizations"] | Authorizations["projects"],
  requiredPermissions: Permission[],
  resourceId: string
): boolean {
  // Retrieve the resource from the provided resources using the resourceId
  const resource = resources[resourceId];

  // Check if the resource exists and if the user has all required permissions
  if (
    !resources || // If the resources does not exist
    !resource || // If the resource does not exist
    !requiredPermissions.every(
      (permission) =>
        (resource.permissions as Permission[]).includes(permission) // Ensure all required permissions are present
    )
  ) {
    // Throw a detailed error indicating insufficient permissions
    throwIsomorphicError({
      name: "Forbidden",
      message: `You do not have sufficient permissions to access resource: ${resourceId}.`,
      statusCode: 403,
      details: {
        name: "Forbidden",
        endpoint: "validate-user-permission",
        message: `You do not have sufficient permissions to access resource: ${resourceId}.`,
        status: 403,
      },
    });
  }

  // If all required permissions are present, return true
  return true;
}
