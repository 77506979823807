import { useEditData_v2 } from "@custom-hooks/edit-data";
import { EditDataOpt, EditDataResult } from "@data-types/generic-hook-type";
import {
  CreatedOrganizationResult,
  CreateOrganizationPayload,
} from "@data-types/organization-types";

/**
 * Custom hook for creating an organization.
 *
 * @param options - Configuration options for the hook, such as enabling or disabling global error handling.
 *
 * @returns An object containing:
 * - `createOrganization`: A function to initiate the creation of an organization.
 * - `isCreatingOrganization`: A boolean indicating whether the organization creation process is ongoing.
 * - `error`: Any error encountered during the organization creation process.
 */
export function useCreateOrganization(
  options: EditDataOpt<CreatedOrganizationResult>
) {
  const { isMutatingData, isLoading, editData, error } = useEditData_v2({
    ...options,
    stopGlobalError: options?.stopGlobalError ?? true,
  });

  /**
   * Creates a new organization.
   *
   * @param params.organizationName - The name of the new organization.
   *
   * @returns A promise resolving to an `EditDataResult` object indicating the result of the API call.
   */
  const createOrganization = async (
    body: CreateOrganizationPayload
  ): Promise<EditDataResult<CreatedOrganizationResult>> => {
    return await editData({
      method: "POST",
      endpoint: "/api/organizations",
      endpointCallLocation: "useCreateOrganization",
      serverType: "backend",
      body: body,
      mutateApis: [
        { url: "/api/auth/session", component: "useGetUserSession" },
      ],
    });
  };

  return {
    createOrganization,
    isCreatingOrganization: isLoading || isMutatingData,
    error,
  };
}
