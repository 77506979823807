import { useCancelSubscription, useSubscriptionDetails } from "@custom-hooks/billing";
import { useGetOrganization } from "@custom-hooks/organizations";
import { useDeleteOrganization } from "@custom-hooks/organizations/hooks/useDeleteOrganization";
import { useGetUserSession } from "@custom-hooks/user";
import { SubscriptionStatusDialog } from "@tw-components/billing/SubscriptionStatusDialog";
import { UpgradingInfraDialog } from "@tw-components/common/UpgradingInfraDialog";
import { GenericDeleteDialog, MessageType } from "@tw-components/ui/iso";
import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";
import { useEffect, useState } from "react";

/**
 * Props for the DeleteOrganizationDialog component.
 *
 * @property {boolean} isOpen - Indicates whether the dialog is open.
 * @property {() => void} onClose - Callback function to close the dialog.
 * @property {string} organizationId - The ID of the organization to be deleted.
 */
type DeleteOrganizationDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  organizationId: string;
};

/**
 * A dialog component for deleting an organization (workspace).
 *
 * This component prompts users with a confirmation dialog before deleting a workspace.
 * It ensures the workspace ID is available and triggers deletion upon confirmation.
 *
 * @param {DeleteOrganizationDialogProps} props - Component props.
 *
 * @returns {JSX.Element} The DeleteOrganizationDialog component.
 */
export function DeleteOrganizationDialog({
  isOpen,
  onClose,
  organizationId
}: DeleteOrganizationDialogProps): JSX.Element {
  const [showSubscriptionStatusDialog, setShowSubscriptionStatusDialog] = useState(false);
  const [showUpgradingInfraDialog, setShowUpgradingInfraDialog] = useState(false);
  const {
    data: session
  } = useGetUserSession();
  const {
    deleteOrganization
  } = useDeleteOrganization({});
  const {
    cancelSubscription
  } = useCancelSubscription({});
  const organization = useGetOrganization(organizationId || "");
  const {
    hasSubscription,
    subscriptionStatusAlertProps
  } = useSubscriptionDetails();
  const router = useRouter();
  const posthog = usePostHog();
  useEffect(() => {
    if (isOpen) {
      posthog.capture("delete_organization_dialog_opened", {
        has_subscription: hasSubscription,
        organization_id: organizationId
      });
    }
  }, [isOpen]);
  return <>
      {organization && organization.ownerId === session?.userId && <GenericDeleteDialog open={isOpen} onClose={onClose} preventInitialOutsideClose={true} beforeEnter={() => {
      if (process.env.NEXT_PUBLIC_UPGRADING_INFRA && process.env.NEXT_PUBLIC_UPGRADING_INFRA.toLowerCase() === "true") {
        onClose();
        setShowUpgradingInfraDialog(true);
      } else if (subscriptionStatusAlertProps !== undefined && hasSubscription) {
        onClose();
        setShowSubscriptionStatusDialog(true);
      }
    }} title={"Delete Workspace?"} description={"Deleting this workspace will remove all projects (Pro and Sandbox), invited users, and deactivate any linked subscriptions. This action is permanent and cannot be undone by you or other users. "} deleteButton={{
      onClick: async setStatus => {
        const error = {
          type: MessageType.Error,
          title: "Failed to delete workspace",
          description: "An error occurred while deleting your workspace. Please try again later. If the issue persists, contact us for assistance."
        };
        if (hasSubscription === undefined || !organizationId) {
          setStatus(error);
        } else {
          if (hasSubscription) {
            const cancelSubscriptionResult = await cancelSubscription({
              deleteOrganization: true
            });
            if (cancelSubscriptionResult.success) {
              router.push("/");
              onClose();
            } else {
              setStatus(error);
            }
          } else {
            const deleteOrganizationResult = await deleteOrganization({
              organizationId
            });
            if (deleteOrganizationResult.success) {
              posthog.capture("organization_deleted", {
                organization_id: organizationId
              });
              router.push("/");
              onClose();
            } else {
              setStatus(error);
            }
          }
        }
      },
      label: "Delete"
    }} variant={{
      type: "with_confirmation",
      firstConfirmation: {
        label: `To verify, type “${organization?.name}” below to delete this workspace for everyone`,
        text: organization?.name ?? "",
        name: "Workspace name"
      },
      secondConfirmationText: "delete workspace"
    }} />}

      {subscriptionStatusAlertProps && <SubscriptionStatusDialog {...subscriptionStatusAlertProps} isOpen={showSubscriptionStatusDialog} onClose={() => setShowSubscriptionStatusDialog(false)} />}

      <UpgradingInfraDialog isOpen={showUpgradingInfraDialog} onClose={() => setShowUpgradingInfraDialog(false)} type={"delete_organization"} data-sentry-element="UpgradingInfraDialog" data-sentry-source-file="DeleteOrganizationDialog.tsx" />
    </>;
}