//
// useWakeProjectUp.ts
//

import { fetchApiRoute_v2 } from "@lib/client-side";
import { useAppState } from "@state/AppStateProvider";

/**
 * Custom hook to wake up a project that has been scaled to zero.
 *
 * @param {string} params.projectId - The ID of the project to wake up.
 *
 * @returns {Object} An object containing the `wakeProjectUp` function.
 */
export function useWakeProjectUp() {
  const { addScalingUpProject, projectsInScalingUpState } = useAppState();

  /**
   * Attempts to wake up the project by making requests to the backend.
   * This function sends up to 3 requests to trigger project startup if it is scaled to zero.
   */
  const wakeProjectUp = async ({ projectId }: { projectId: string }) => {
    if (projectsInScalingUpState.includes(projectId)) {
      return;
    }

    addScalingUpProject(projectId);

    for (let i = 0; i < 3; i++) {
      try {
        await fetchApiRoute_v2(
          {
            endpoint: `/api/projects/${projectId}/databases?reportError=false`,
            endpointCallLocation: "useWakeProjectUp",
            method: "GET",
            serverType: "backend",
          },
          false
        );
      } catch (error) {
        console.error("Failed to wake project up:", error);
      }

      await new Promise((resolve) => setTimeout(resolve, 3000));
    }
  };

  return {
    wakeProjectUp,
  };
}
