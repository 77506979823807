//
// useGetProjectJobs.ts
//

import { useFetchData } from "@custom-hooks/fetch-data";
import { Jobs, JobType } from "@data-types/jobs-type";
import { FetchProjectJobsResult } from "@data-types/projects-types";
import { useEffect, useState } from "react";
import { getJobStatus } from "../utils/getJobStatus";

/**
 * Custom hook to fetch and monitor project jobs based on a given project ID, with tracking for job progress.
 *
 * @param {string} projectId - The ID of the project for which to fetch job data.
 *
 * @returns {FetchProjectJobsResult<Jobs>} The result object with the list of jobs
 *   (or `undefined` if no jobs are available), UI state indicators, and a function to manage fetched data.
 */
export function useGetProjectJobs(
  projectId: string,
  refreshInterval?: number
): FetchProjectJobsResult<Jobs> {
  const [isAnyJobInProgress, setIsAnyJobInProgress] = useState<boolean>(false);
  const [isAnyJobWithError, setIsAnyJobWithError] = useState<boolean>(false);
  const [projectStatusString, setProjectStatusString] = useState<
    string | undefined
  >(undefined);
  const [projectErrorString, setProjectErrorString] = useState<
    string | undefined
  >(undefined);
  const [errors, setErrors] = useState<string[]>([]);

  const { data, ...fetchDetails } = useFetchData<Jobs>({
    fetchCondition: projectId !== "",
    source: "backend",
    swrKey: {
      url: `/api/projects/${projectId}/jobs/nodes`,
      component: "useGetProjectJobs",
    },
    options: {
      refreshInterval: refreshInterval
        ? refreshInterval
        : isAnyJobInProgress || isAnyJobWithError
          ? 10000
          : 0,
    },
  });

  useEffect(() => {
    if (!data) return;

    let testIsInProgress = false;
    let testIsInError = false;

    const jobTypes: JobType[] = [];
    const errors: string[] = [];

    data.forEach((job) => {
      const status = getJobStatus(job);

      switch (status) {
        case "completed":
          break;
        case "error":
          errors.push(job.status);
          testIsInError = true;
          break;
        case "progress":
        default:
          testIsInProgress = true;
      }

      jobTypes.push(job.type);
    });

    const jobTypePriority: JobType[] = [
      "upgrade_project",
      "migrate_project",
      "create_project",
      "create_node",
      "delete_project",
      "delete_node",
    ];

    for (const jobType of jobTypePriority) {
      if (jobTypes.includes(jobType)) {
        switch (jobType) {
          case "upgrade_project":
          case "migrate_project":
            setProjectStatusString("Upgrading your project...");
            setProjectErrorString(
              "It seems that the upgrade was not completed successfully, and some steps may have failed."
            );
            break;
          case "create_project":
            setProjectStatusString("Creating your project...");
            setProjectErrorString(
              "It seems that the project creation was not completed successfully, and some steps may have failed."
            );
            break;
          case "create_node":
            setProjectStatusString("Adding new node to your project...");
            setProjectErrorString(
              "It seems that the node creation was not completed successfully, and some steps may have failed."
            );
            break;
          case "delete_project":
            setProjectStatusString("Deleting your project...");
            setProjectErrorString(
              "It seems that the project deletion was not completed successfully, and some steps may have failed."
            );
            break;
          case "delete_node":
            setProjectStatusString("Removing a node from your project...");
            setProjectErrorString(
              "It seems that the node deletion was not completed successfully, and some steps may have failed."
            );
            break;
          default:
            break;
        }
        break;
      }
    }

    setIsAnyJobInProgress(testIsInProgress);
    setIsAnyJobWithError(testIsInError);
    setErrors(errors);
  }, [data, projectId]);

  return {
    data,
    isAnyJobInProgress,
    isAnyJobWithError,
    projectStatusString,
    projectErrorString,
    errors,
    ...fetchDetails,
  };
}
