//
// useHasOrganizationPermission.ts
//

import { useGetCurrentOrganizationId } from "@custom-hooks/organizations";
import { useGetUserSession } from "@custom-hooks/user";
import { OrganizationPermission } from "@data-types/role-based-access-control-types";
import { Authorizations } from "@data-types/user-types";
import { validateUserPermission } from "@lib/role-based-access-control/iso-utils";

/**
 * Hook to check if the user has the required permissions for a specific organization.
 *
 * @param requiredPermissions - The list of permissions required.
 * @returns `true` if the user has all required permissions, otherwise `false`.
 */
export function useHasOrganizationPermission(
  requiredPermissions: OrganizationPermission[]
): boolean | undefined {
  // Fetch the user session
  const { data: session, hasData } = useGetUserSession();

  // Get actual organization ID
  const actualOrganizationId = useGetCurrentOrganizationId();

  // If session data is not available, return false
  if (!hasData || !session || actualOrganizationId === undefined) {
    return undefined;
  }

  // If user doesn't have access to the organization, return false
  if (actualOrganizationId === false) {
    return false;
  }

  // Retrieve user's authorizations
  const authorizations: Authorizations["organizations"] =
    session.authorizations?.organizations || {};

  // Try validating the user's permissions
  try {
    return validateUserPermission(
      authorizations,
      requiredPermissions,
      actualOrganizationId
    );
  } catch {
    return false;
  }
}
