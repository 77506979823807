//
// useGetProjectApiKey.ts
//

import { useFetchData } from "@custom-hooks/fetch-data";
import { useGetFetchConditionBasedOnProject } from "@custom-hooks/fetch-data/hooks/useGetFetchConditionBasedOnProject";
import { ApiKeys } from "@data-types/api-key-types";
import { FetchHookResult } from "@data-types/generic-hook-type";
import { ProjectStatus } from "@data-types/projects-types";

/**
 * Custom hook to fetch and manage API keys for a specific project.
 *
 * @param {string} projectId - The unique identifier of the project for which to fetch the API keys.
 * @param {number} [errorRetryCount] - Max error retry count.
 *
 * @returns {FetchHookResult<ApiKeys>} The result object with the list of API keys
 *   (or `undefined` if no api keys are available), UI state indicators, and a function to manage fetched data.
 */
export function useGetProjectApiKey(
  projectId: string,
  errorRetryCount?: number
): FetchHookResult<ApiKeys> {
  const fetchCondition = useGetFetchConditionBasedOnProject({
    id: projectId,
    projectStatus: ProjectStatus.RUNNING_AND_WORKING,
  });

  const { data, ...fetchDetails } = useFetchData<ApiKeys>({
    fetchCondition: fetchCondition && projectId !== "",
    source: "backend",
    swrKey: {
      url: `/api/projects/${projectId}/apikey`,
      component: "useGetProjectApiKey",
    },
    options: {
      revalidateOnFocus: false,
      errorRetryCount,
    },
  });

  return {
    data: data?.sort((a, b) => a.name.localeCompare(b.name)),
    ...fetchDetails,
  };
}
