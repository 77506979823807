//
// withPermissionsGuard.ts
//

import { useUserPermissions } from "@custom-hooks/role-based-access-control";
import { UserPermissions } from "@data-types/role-based-access-control-types";
import { useRouter } from "next/router";

/**
 * HOC to enforce multiple permissions for accessing a component.
 *
 * @param Component - The component to protect.
 * @param permissions - An array of permissions that all must be `true` for access.
 */
export function withPermissionsGuard<T extends object>({
  Component,
  permissions,
  NoPermissionComponent
}: {
  Component: React.ComponentType<T>;
  permissions: (keyof UserPermissions)[];
  NoPermissionComponent?: React.ReactNode;
}) {
  return function ProtectedComponent(props: T): JSX.Element | null {
    const userPermissions = useUserPermissions();
    const router = useRouter();
    const hasAllPermissions = permissions.every(perm => userPermissions[perm]);
    if (!hasAllPermissions) {
      if (NoPermissionComponent) {
        return <>{NoPermissionComponent}</>;
      } else {
        return null;
      }
    }
    return <Component {...props} />;
  };
}