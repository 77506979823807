import { Alerts, GenericDialog, MessageType } from "@tw-components/ui/iso";

/**
 * Props for the MissingPermissionsToCreateSubscriptionDialog component.
 *
 * @property {boolean} isOpen - Determines if the dialog is visible or not.
 * @property {() => void} onClose - Callback function to close the dialog.
 */
type MissingPermissionsToCreateSubscriptionDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

/**
 * A dialog displayed when the user lacks the necessary permissions to create a subscription for a workspace.
 * It informs the user that they need specific permissions and prompts them to contact the workspace owner.
 *
 * @param {MissingPermissionsToCreateSubscriptionDialogProps} props - The props for the dialog component.
 *
 * @returns {JSX.Element} The JSX code for rendering the dialog.
 */
export function MissingPermissionsToCreateSubscriptionDialog({
  isOpen,
  onClose
}: MissingPermissionsToCreateSubscriptionDialogProps): JSX.Element {
  return <>
      <GenericDialog onClose={onClose} open={isOpen} title={"Missing Permissions"} body={<Alerts messages={[{
      type: MessageType.Error,
      title: "The action you are trying to perform requires a subscription but you do not have the necessary permissions to create a subscription. Please contact the owner of the workspace."
    }]} />} submitButtonProps={{
      variant: "primary",
      label: "OK",
      onClick: onClose
    }} size="xl" data-sentry-element="GenericDialog" data-sentry-source-file="MissingPermissionsToCreateSubscriptionDialogProps.tsx" />
    </>;
}