"use client";

//
// ApplicationLayout.tsx
//
import GlobalStyle from "@components/GlobalStyle";
import Profile from "@components/User/Profile";
import { useSubscriptionDetails } from "@custom-hooks/billing";
import { useGetProjectLookupKey } from "@custom-hooks/billing/hooks/useGetProjectLookupKey";
import { useGetCurrentOrganizationId, useLastVisitedOrganization } from "@custom-hooks/organizations";
import { useGetCurrentProjectId, useGetProjectStatus, useGetProjectStorageInfo, useGetUserProjectsByOrganizationId } from "@custom-hooks/projects";
import { useWakeProjectUp } from "@custom-hooks/projects/hooks/useWakeProjectUp";
import { useUserPermissions } from "@custom-hooks/role-based-access-control";
import { useCustomRouter } from "@custom-hooks/useCustomRouter";
import { useGetUserSession } from "@custom-hooks/user";
import { AddonLookupKey } from "@data-types/billing-types";
import { Projects, ProjectStatus } from "@data-types/projects-types";
import OpenDialog from "@generic-components/OpenDialog";
import SnackNotification from "@generic-components/SnackNotification";
import * as Headless from "@headlessui/react";
import AppNavbar from "@layouts/ApplicationLayout/AppNavbar";
import { CircleBrandMark } from "@layouts/ApplicationLayout/CircleBrandMark";
import { FullBrandMark } from "@layouts/ApplicationLayout/FullBrandMark";
import { UserBadge } from "@layouts/ApplicationLayout/UserBadge";
import { useCollapseLayout } from "@layouts/lib/useCollapseLayout";
import { useGetSidebarTree } from "@layouts/lib/useGetSidebarTree";
import CommunityIcon from "@layouts/svg-icon/community-icon.svg";
import ConnectIcon from "@layouts/svg-icon/connect-icon.svg";
import LogoutIcon from "@layouts/svg-icon/logout-icon.svg";
import MessageSquareTypingIcon from "@layouts/svg-icon/message-square-typing-icon.svg";
import MobileCloseIcon from "@layouts/svg-icon/mobile-close-icon.svg";
import WarningIcon from "@layouts/svg-icon/red-warning-circle-icon.svg";
import SettingsIcon from "@layouts/svg-icon/settings-icon.svg";
import ToggleSidebarState from "@layouts/svg-icon/toggle-sidebar-state-icon.svg";
import UserProfileCircleIcon from "@layouts/svg-icon/user-profile-circle-icon.svg";
import { DecorationBottomLeft, DecorationBottomRight, DecorationTopLeft, DecorationTopRight } from "@layouts/svg-illustration/sing-in-decoration";
import Wave1 from "@layouts/svg-illustration/wave-1.svg";
import { SQLITE_CLOUD_ORGANIZATION_ID } from "@lib/billing/constants";
import { signOut } from "@lib/client-side";
import { generateRandomId } from "@lib/iso-utils";
import CssBaseline from "@mui/material/CssBaseline";
import { useAppState } from "@state/AppStateProvider";
import SubscriptionStatusBanner from "@tw-components/billing/SubscriptionStatusBanner";
import { UpgradeDialog } from "@tw-components/billing/upgrade-dialog/UpgradeDialog";
import { UpgradeProjectButtonWithPermissionsGuard } from "@tw-components/billing/UpgradeProjectButtonWithPermissionsGuard";
import { OrganizationSettingsSideSheet } from "@tw-components/organizations/OrganizationSettingsSideSheet";
import { ConnectDialog } from "@tw-components/projects/connect-dialog/ConnectDialog";
import { ContactDialog } from "@tw-components/support/ContactDialog";
import { Button, NavbarItem, ProgressBar, Sidebar, SidebarBody, SidebarDivider, SidebarFooter, SidebarHeader, SidebarItem, SidebarLayout, SidebarSection } from "@tw-components/ui/iso";
import clsx from "clsx";
import Link from "next/link";
import { usePostHog } from "posthog-js/react";
import React, { Fragment, MutableRefObject, useEffect, useRef, useState } from "react";
export function ApplicationLayout({
  children
}: {
  children: React.ReactNode;
}) {
  // Extracts the current path with custom router hook
  const {
    router,
    asPath,
    pathname
  } = useCustomRouter();

  // Parse pathname to extract actual slug
  const actualSlug = pathname?.split("/").at(-1);
  // Parses query parameters from the URL path to check for "profile"
  const queryParams = asPath && new URLSearchParams(asPath.split("?")[1]);
  const profile = queryParams && queryParams.get("profile");

  // Get session
  const {
    data: session,
    hasData: availableUserInfo,
    isLoading: loadingUser,
    isError: errorUser,
    isValidating: validatingUser
  } = useGetUserSession();

  // Show the layout only when the user info are available
  const [showLayout, setShowLayout] = useState(false);
  useEffect(() => {
    if (availableUserInfo && !loadingUser && !errorUser) {
      setTimeout(() => {
        setShowLayout(true);
      }, 500);
    }
  }, [session, availableUserInfo, router, loadingUser, errorUser]);

  // Get current organization ID
  const organizationId = useGetCurrentOrganizationId();

  // Fetch users projects
  const {
    data: projects,
    hasData: availableProjects
  } = useGetUserProjectsByOrganizationId(organizationId);

  // Extract projects with at least one node
  const filteredProjectsWithNodes = availableProjects ? (projects as Projects).filter(project => project.nodes_count > 0) : [];

  // Extract projects with at least one node in creation
  const filteredProjectsWithNodesInCreation = availableProjects ? (projects as Projects).filter(project => project.creating_count > 0) : [];
  const projectId = useGetCurrentProjectId();
  const projectType = useGetProjectLookupKey(projectId || "");
  const {
    subscriptionStatus
  } = useSubscriptionDetails();
  const {
    setLastVisitedOrganization
  } = useLastVisitedOrganization();
  const {
    status
  } = useGetProjectStatus({
    projectId: projectId || ""
  });
  const {
    wakeProjectUp
  } = useWakeProjectUp();
  const permissions = useUserPermissions();

  //handle redirect based on project and organization id
  useEffect(() => {
    if (router) {
      //if projectId is false means that it is not an organization of the current user. so redirect to 404 page
      if (organizationId === false) {
        router.replace(`/not-found`);
      } else if (organizationId) {
        if (availableProjects) {
          //if projectId is false means that it is not a project of the current user. so redirect to 404 page
          if (projectId === false) {
            router.replace(`/not-found`);
          } else if (projectId) {
            // if subscription is paused redirect to the billing page
            if (subscriptionStatus === "unpaid") {
              switch (projectType) {
                case AddonLookupKey.PRO_PROJECT:
                case AddonLookupKey.SCALE_PROJECT:
                  if (permissions.canUpdateBillingDetails) {
                    router.push(`/organizations/${organizationId}/billing`);
                  } else {
                    router.push(`/organizations/${organizationId}/projects`);
                  }
                  break;
                case AddonLookupKey.SANDBOX_PROJECT:
                default:
                  break;
              }
            } else if (organizationId !== SQLITE_CLOUD_ORGANIZATION_ID) {
              switch (status) {
                case ProjectStatus.RUNNING_BUT_NOT_WORKING:
                case ProjectStatus.DEACTIVATED:
                case ProjectStatus.NOT_READY:
                  router.push(`/organizations/${organizationId}/projects`);
                  break;
                case ProjectStatus.SCALED_TO_ZERO:
                  wakeProjectUp({
                    projectId: projectId || ""
                  });
                  router.push(`/organizations/${organizationId}/projects`);
                  break;
                default:
                  break;
              }
            }
          }
        }
      }
    }
  }, [availableProjects, projectId, actualSlug, projects, router, organizationId, status]);
  useEffect(() => {
    if (organizationId) {
      setLastVisitedOrganization(organizationId);
    }
  }, [organizationId]);

  // Check if we are inside a project
  let projectIdToUse: string = "";
  if (projectId) {
    projectIdToUse = projectId;
  } else {
    if (availableProjects) {
      projectIdToUse = projects![0].id;
    }
  }
  // Fetch storage info for current project id
  const {
    data: storageInfo,
    hasData: availableStorageInfo
  } = useGetProjectStorageInfo(projectIdToUse);

  // Determine storage usage and availability
  const availableStorage = availableStorageInfo ? storageInfo?.availableStorage : "";
  const usedStorage = availableStorageInfo ? storageInfo?.usedStorage : "";
  const storagePercentage = availableStorageInfo ? storageInfo?.usedStoragePercentage : 0;
  const warningStorage = availableStorageInfo ? storageInfo?.warning : false;

  // Get state context
  const {
    setTabId
  } = useAppState();
  useEffect(() => {
    const tabId = "sqlitecloud-tab-" + generateRandomId();
    setTabId(tabId);
  }, [setTabId]);

  // Generates the sidebar tree based on whether a project is selected or not
  const sidebarItems = useGetSidebarTree();
  /**
   * `collapseSidebar` state, is a boolean that
   * controls the sidebar's appearance in desktop view. If true, only sidebar
   * icons will be shown, hiding the labels or extra content.
   */
  let [collapseSidebar, setCollapseSidebar] = useState(false);
  const handleCollapseSidebar = () => {
    setCollapseSidebar(prevState => !prevState);
  };

  // Check if we are in a condition where the layout has to be collpased by default
  const collapsedLayout = useCollapseLayout();

  /**
   * `showSidebar` state, is a boolean that
   * controls the sidebar's appearance in mobile view.
   */
  let [showMobileSidebar, setShowMobileSidebar] = useState(false);

  // Reference for the profile dialog box
  const profileRefDialog = useRef<MutableRefObject<any | null>>(null);
  const [showContactDialog, setShowContactDialog] = useState(false);

  // State to track whether to auto-open the profile dialog
  const [autoOpenProfile, setAutoOpenProfile] = useState(false);

  // Sets autoOpenProfile to true if the "profile" query parameter exists
  useEffect(() => {
    if (profile) {
      setAutoOpenProfile(true);
    }
  }, [profile]);

  // Opens the profile dialog when `autoOpenProfile` is true and user loading is complete
  useEffect(() => {
    if (autoOpenProfile && !loadingUser) {
      // @ts-expect-error OpenDialog method not typed for TypeScript
      profileRefDialog.current?.open();
    }
  }, [autoOpenProfile, loadingUser]);

  // Controls the visibility of the authentication layout by adding a "hidden" class
  // once the main layout is ready (indicated by showLayout). This triggers a smooth
  // opacity transition to the Sidebar layout. The 200ms delay aligns with the transition effect.
  const [hideAuth, setHideAuth] = useState(false);
  useEffect(() => {
    if (showLayout) {
      setTimeout(() => {
        setHideAuth(true);
      }, 200);
    }
  }, [showLayout]);

  // Manages the open/closed state of sidebar subitems.
  // Initializes as an empty object, which will later hold boolean values
  // for each sidebar item's unique key (slug) to track its open/closed status.
  // If a valid category (without dynamic segments) is detected in the URL path,
  // its corresponding sidebar item will be set to open by default.
  const [openSubitems, setOpenSubitems] = useState<{
    [key: string]: boolean;
  }>(() => {
    let open: {
      [key: string]: boolean;
    } = {};
    const actualCategory = pathname?.split("/").at(-2);
    if (actualCategory && !actualCategory.includes("[")) {
      open[actualCategory] = true;
    }
    return open;
  });

  // Toggles the open/closed state of a specific sidebar subitem by its unique key (slug).
  // When collapseSidebar is true, it will ensure the item is open. Otherwise, it toggles
  // the item's state based on its current status (open if closed, close if open).
  const handleOpenSubitem = (slug: string) => {
    // Create a shallow clone of the current openSubitems state to avoid direct mutation
    let newOpenSubitems = structuredClone(openSubitems);
    if (collapseSidebar) {
      newOpenSubitems[slug] = true;
    } else {
      newOpenSubitems[slug] = !newOpenSubitems[slug];
    }
    setOpenSubitems(newOpenSubitems);
    setCollapseSidebar(false);
  };
  const showUpgradeCallToAction = projectId !== false && projectId !== undefined && projectType === AddonLookupKey.SANDBOX_PROJECT &&
  //TODO:Remove when we can offers also the scale plan
  !collapseSidebar && availableStorageInfo;
  const [isConnectDialogOpen, setIsConnectDialogOpen] = useState(false);
  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false);
  const [showOrganizationSettingsSideSheet, setShowOrganizationSettingsSideSheet] = useState(false);
  const posthog = usePostHog();
  const {
    subscriptionStatusAlertProps
  } = useSubscriptionDetails();

  // Render component
  return <>
      <CssBaseline data-sentry-element="CssBaseline" data-sentry-source-file="ApplicationLayout.tsx" />
      <GlobalStyle data-sentry-element="GlobalStyle" data-sentry-source-file="ApplicationLayout.tsx" />
      <div className={clsx("tw-transition-opacity tw-duration-500 tw-ease-in-out", showLayout ? "tw-pointer-events-none tw-opacity-0" : "tw-opacity-100", hideAuth ? "tw-hidden" : "")}>
        <div className="tw-relative tw-h-screen tw-overflow-hidden">
          <div className="tw-absolute tw-left-6 tw-top-6 tw-z-[51]">
            <CircleBrandMark data-sentry-element="CircleBrandMark" data-sentry-source-file="ApplicationLayout.tsx" />
          </div>
          <div className="tw-relative tw-z-50 tw-flex tw-h-full tw-items-center tw-justify-center tw-overflow-auto tw-p-6">
            <div className="tw-max-w-[22.875rem]">
              <FullBrandMark data-sentry-element="FullBrandMark" data-sentry-source-file="ApplicationLayout.tsx" />
            </div>
          </div>
          <DecorationTopLeft data-sentry-element="DecorationTopLeft" data-sentry-source-file="ApplicationLayout.tsx" />
          <DecorationBottomLeft data-sentry-element="DecorationBottomLeft" data-sentry-source-file="ApplicationLayout.tsx" />
          <DecorationTopRight data-sentry-element="DecorationTopRight" data-sentry-source-file="ApplicationLayout.tsx" />
          <DecorationBottomRight data-sentry-element="DecorationBottomRight" data-sentry-source-file="ApplicationLayout.tsx" />
        </div>
      </div>
      <div className={clsx("tw-h-full tw-max-h-full", "tw-transition-opacity tw-duration-500 tw-ease-in-out", hideAuth ? "tw-visible tw-opacity-100" : "tw-invisible tw-opacity-0")}>
        {showLayout && <SidebarLayout showMobileSidebar={showMobileSidebar} setShowMobileSidebar={setShowMobileSidebar} collapseSidebar={collapseSidebar || collapsedLayout} navbar={<AppNavbar setShowMobileSidebar={setShowMobileSidebar} onContactUsButtonClicked={() => setShowContactDialog(true)} profileRefDialog={profileRefDialog} />} sidebar={<Sidebar>
                <SidebarHeader>
                  <div className={clsx("tw-flex tw-flex-row tw-items-center tw-py-3", "tw-cursor-default", collapseSidebar && !showMobileSidebar ? "tw-justify-center" : "tw-justify-between tw-transition-colors tw-duration-150")}>
                    <Link href="/">
                      <CircleBrandMark size={collapseSidebar && !showMobileSidebar ? collapsedLayout ? 44 : 0 : 44} />
                    </Link>
                    {!collapsedLayout && <Button onClick={handleCollapseSidebar} size="small" variant="tertiary" icon={<ToggleSidebarState />} className="tw-hidden lg:tw-flex" />}
                    <div className="tw-block lg:tw-hidden">
                      <Headless.CloseButton as={NavbarItem} aria-label="Close navigation">
                        <MobileCloseIcon className="!tw-h-10 !tw-w-10" />
                      </Headless.CloseButton>
                    </div>
                  </div>
                </SidebarHeader>

                <>
                  <SidebarBody className={clsx(collapseSidebar && !showMobileSidebar ? "tw-items-center" : "")}>
                    {/* Navigation Items */}
                    <SidebarSection className="tw-pb-4 lg:!tw-hidden">
                      <UserBadge />
                    </SidebarSection>

                    <div id="error-portal"></div>

                    {/* Navigation Items */}
                    {organizationId && <SidebarSection>
                        {sidebarItems?.pages?.map((page, i) => {
                if (page) {
                  return <Fragment key={page.slug}>
                                <SidebarItem key={page.slug} current={actualSlug === page.slug} href={page.href || undefined} collapsed={collapseSidebar} disabled={page.disabled} label={page.label} icon={page.icon} experimental={page.experimental} hasSubItems={!!page.children && !collapseSidebar} onClick={() => {
                      if (page.children) {
                        handleOpenSubitem(page.slug);
                      }
                      posthog.capture("nav_bar_item_clicked", {
                        page: page.slug
                      });
                    }} expanded={!page.disabled && page.children && openSubitems && openSubitems[page.slug] == true ? true : undefined} />
                                <SidebarSection className={clsx(collapseSidebar || !page.children || page.disabled ? "tw-hidden" : "", "tw-overflow-hidden tw-transition-all tw-duration-500 tw-ease-in-out", openSubitems[page.slug] ? "tw-max-h-screen" : "tw-max-h-0")}>
                                  {page.children?.map((page, i) => {
                        if (page) {
                          return <SidebarItem className={clsx(collapseSidebar ? "tw-pointer-events-none" : "")} variant="inner" key={page.slug} current={pathname?.split("/").at(-1) === page.slug && !collapseSidebar} href={page.href || undefined} collapsed={collapseSidebar} disabled={false} label={page.label} icon={page.icon} experimental={page.experimental} />;
                        }
                      })}
                                </SidebarSection>
                              </Fragment>;
                }
              })}
                        {!projectId && <SidebarItem key={"organization-settings"} onClick={() => setShowOrganizationSettingsSideSheet(true)} label={"Settings"} icon={<SettingsIcon />} collapsed={collapseSidebar} />}
                      </SidebarSection>}
                    <SidebarDivider className="lg:tw-hidden" />
                    <SidebarSection className="lg:!tw-hidden">
                      <div className="tw-dark:tw-text-text-subTitle-dark tw-text-12px-regular tw-uppercase tw-text-text-subTitle-light">
                        Support
                      </div>
                      <SidebarItem key={"community"} href={"https://github.com/orgs/sqlitecloud/discussions/"} target="_blank" label={"Community"} icon={<CommunityIcon />} />
                      <SidebarItem key={"contact-us"} onClick={() => setShowContactDialog(true)} label={"Contact Us"} icon={<MessageSquareTypingIcon />} />
                    </SidebarSection>
                    <SidebarDivider className=" lg:tw-hidden" />
                    <SidebarSection className="lg:!tw-hidden">
                      <div className="tw-dark:tw-text-text-subTitle-dark tw-text-12px-regular tw-uppercase tw-text-text-subTitle-light">
                        USER SETTING
                      </div>
                      <SidebarItem key={"profile"} onClick={() => {
                // @ts-expect-error OpenDialog not writter for typescript
                profileRefDialog.current?.open();
              }} label={"Profile"} icon={<UserProfileCircleIcon />} />
                      <SidebarItem key={"sign-out"} onClick={async () => {
                signOut();
              }} label={"Sign out"} icon={<LogoutIcon />} />
                    </SidebarSection>
                  </SidebarBody>

                  {organizationId && <SidebarFooter>
                      {showUpgradeCallToAction && <div className="tw-dark:tw-border-brandBlues-ice-dark tw-relative tw-mb-8 tw-flex tw-flex-col tw-gap-3 tw-overflow-hidden tw-rounded-lg tw-border tw-border-brandBlues-ice-light tw-p-4">
                          <Wave1 className="tw-pointer-events-none tw-absolute tw-bottom-0 tw-left-0 -tw-z-10" />
                          {warningStorage && <div className="tw-flex tw-flex-col tw-gap-1.5">
                              <WarningIcon />

                              <div className="tw-text-12px-regular tw-text-brandBlues-darkBlue-light dark:tw-text-brandBlues-darkBlue-dark">
                                You are near your project storage limit. Upgrade
                                for more storage.
                              </div>
                            </div>}

                          <div className="tw-flex tw-flex-col tw-gap-1">
                            <div className="tw-flex tw-flex-row tw-justify-between">
                              <p className="tw-text-12px-semiBold tw-text-text-subTitle-light dark:tw-text-text-subTitle-dark">
                                Storage
                              </p>
                              <p className="tw-text-12px-regular tw-text-text-subTitle-light dark:tw-text-text-subTitle-dark">
                                {usedStorage} / {availableStorage}
                              </p>
                            </div>

                            <ProgressBar value={storagePercentage!} variant={warningStorage ? "error" : "base"} />
                          </div>

                          {(() => {
                switch (projectType) {
                  case AddonLookupKey.SANDBOX_PROJECT:
                    return <UpgradeProjectButtonWithPermissionsGuard onClick={() => setShowUpgradeDialog(true)} className="tw-w-full" size="small" />;
                    break;
                  // TODO: remove the comments when we have the scale plan
                  // case AddonLookupKey.PRO_PROJECT:
                  //   return (
                  //     <Button
                  //       onClick={() => window.open(BOOK_A_MEETING_CALENDAR_URL, "_blank")}
                  //       size="small"
                  //       variant="primary"
                  //       label={
                  //         collapseSidebar
                  //           ? ""
                  //           : "Upgrade To Scale"
                  //       }
                  //     />
                  //   );
                  //   break;
                }
              })()}
                        </div>}
                      {filteredProjectsWithNodes.length > 0 ? <Button size={collapseSidebar ? "small" : "large"} variant="secondary" icon={<ConnectIcon />} label={collapseSidebar ? "" : "Connect"} className={clsx(collapseSidebar ? "tw-self-center" : "")} onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              e.stopPropagation();
              setIsConnectDialogOpen(true);
            }} /> : collapseSidebar ? <Button size={"small"} variant="primary" icon={<ConnectIcon />} className={"tw-self-center"} disabled={true} /> : <div className=" tw-dark:tw-border-brandBlues-ice-dark tw-relative tw-flex tw-flex-col tw-gap-3 tw-overflow-hidden tw-rounded-lg tw-border tw-border-brandBlues-ice-light tw-p-4">
                          <p className="tw-text-16px-light tw-text-brandBlues-brandSecondary-light dark:tw-text-brandBlues-brandSecondary-dark">
                            Get Started!
                          </p>
                          <p className="tw-text-12px-regular tw-text-brandBlues-darkBlue-light dark:tw-text-brandBlues-darkBlue-dark">
                            {filteredProjectsWithNodesInCreation.length > 0 ? "When your project is ready, use this button to get your connection string." : "Create your first project. When it's ready, use this button to get your connection string."}
                          </p>
                          <Button size={"large"} variant="primary" icon={<ConnectIcon />} label={collapseSidebar ? "" : "Connect"} disabled={true} />
                        </div>}
                    </SidebarFooter>}
                </>
              </Sidebar>}>
            <div className={clsx("tw-flex tw-min-h-0 tw-flex-1 tw-flex-col tw-pb-2", projectId ? "tw-overflow-y-auto" : "tw-overflow-hidden")}>
              {children}
            </div>

            {subscriptionStatusAlertProps && <div className="tw-sticky tw-bottom-0 tw-mt-2 tw-w-full tw-bg-white">
                <SubscriptionStatusBanner {...subscriptionStatusAlertProps} />
              </div>}
          </SidebarLayout>}
      </div>
      <OrganizationSettingsSideSheet isOpen={showOrganizationSettingsSideSheet} onClose={() => setShowOrganizationSettingsSideSheet(false)} data-sentry-element="OrganizationSettingsSideSheet" data-sentry-source-file="ApplicationLayout.tsx" />

      <ConnectDialog isOpen={isConnectDialogOpen} onClose={() => {
      setIsConnectDialogOpen(false);
    }} projectId={projectId ? projectId : undefined} data-sentry-element="ConnectDialog" data-sentry-source-file="ApplicationLayout.tsx" />

      <UpgradeDialog isOpen={showUpgradeDialog} onClose={() => setShowUpgradeDialog(false)} projectId={projectId || ""} triggerSource="storage_usage_warning" data-sentry-element="UpgradeDialog" data-sentry-source-file="ApplicationLayout.tsx" />

      <ContactDialog isOpen={showContactDialog} onClose={() => setShowContactDialog(false)} source={"Contact Us Button"} data-sentry-element="ContactDialog" data-sentry-source-file="ApplicationLayout.tsx" />

      {/* Profile Dialog Box */}
      {/* @ts-expect-error OpenDialog method not typed for TypeScript*/}
      <OpenDialog ref={profileRefDialog} style="none" size="small-modal" data-sentry-element="OpenDialog" data-sentry-source-file="ApplicationLayout.tsx">
        <Profile data-sentry-element="Profile" data-sentry-source-file="ApplicationLayout.tsx" />
      </OpenDialog>

      <SnackNotification data-sentry-element="SnackNotification" data-sentry-source-file="ApplicationLayout.tsx" />
    </>;
}