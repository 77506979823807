import { useGetProjectJobs } from "@custom-hooks/jobs";
import { useGetProjectStatus } from "@custom-hooks/projects/hooks/useGetProjectStatus";
import { ApiKey } from "@data-types/api-key-types";
import { Database } from "@data-types/databases-types";
import { Project, ProjectStatus } from "@data-types/projects-types";
import { useMemo } from "react";

type UseErrorMessagesProps = {
  project: {
    isError: boolean;
    selected: string | undefined;
    isEmpty: boolean;
    options: {
      options: Record<string, Project>;
      sortedKeys: string[];
    };
  };
  database: {
    isError: boolean;
    selected: string | undefined;
    options: {
      options: Record<string, Database>;
      sortedKeys: string[];
    };
    isEmpty: boolean;
  };
  user: {
    isError: boolean;
  };
  apiKey: {
    isError: boolean;
    selected: string | undefined;
    options: { options: Record<string, ApiKey>; sortedKeys: string[] };
    isEmpty: boolean;
  };
  connectionString: string;
};

/**
 * This hook centralizes the logic for determining error states and generating error messages
 * for projects, databases, users, API keys, and connection strings. It also tracks if a project
 * is failing due to specific conditions.
 *
 * @param {UseErrorMessagesProps} props - The properties required to determine error states.
 * @param {object} props.project - Project-related information.
 * @param {boolean} props.project.isError - Indicates if there was an error fetching the project data.
 * @param {string | undefined} props.project.selected - The ID of the selected project.
 * @param {boolean} props.project.isEmpty - Indicates if no projects are available.
 * @param {object} props.project.options - Available project options.
 *
 * @param {object} props.database - Database-related information.
 * @param {boolean} props.database.isError - Indicates if there was an error fetching the databases.
 * @param {string | undefined} props.database.selected - The name of the selected database.
 * @param {object} props.database.options - Available database options.
 * @param {boolean} props.database.isEmpty - Indicates if no databases are available for the project.
 *
 * @param {object} props.user - User-related information.
 * @param {boolean} props.user.isError - Indicates if there was an error fetching the users.
 *
 * @param {object} props.apiKey - API key-related information.
 * @param {boolean} props.apiKey.isError - Indicates if there was an error fetching the API keys.
 * @param {string | undefined} props.apiKey.selected - The name of the selected API key.
 * @param {object} props.apiKey.options - Available API key options.
 * @param {boolean} props.apiKey.isEmpty - Indicates if no API keys are available for the selected user.
 *
 * @param {string} props.connectionString - The generated connection string.
 *
 * @returns {object} An object containing error messages and project status.
 * @property {object} errorMessages - An object with error messages for each resource type.
 * @property {string | undefined} errorMessages.project - The error message for the project, if any.
 * @property {string | undefined} errorMessages.database - The error message for the database, if any.
 * @property {string | undefined} errorMessages.user - The error message for the user, if any.
 * @property {string | undefined} errorMessages.apiKey - The error message for the API key, if any.
 * @property {string | undefined} errorMessages.connectionString - The error message for the connection string, if any.
 * @property {boolean} isProjectFailing - Indicates if the project is considered to be in a failing state.
 */
export const useGetErrorMessages = ({
  project,
  database,
  user,
  apiKey,
  connectionString,
}: UseErrorMessagesProps) => {
  const { status } = useGetProjectStatus({ projectId: project.selected ?? "" });

  const {
    isAnyJobInProgress,
    isAnyJobWithError,
    projectStatusString,
    projectErrorString,
  } = useGetProjectJobs(project.selected ?? "");

  const projectError = useMemo(() => {
    if (project.isError) {
      return "Network error: Unable to fetch projects.";
    }

    if (project.selected) {
      if (status === ProjectStatus.SCALED_TO_ZERO) {
        return "Error: Paused.";
      }

      if (status === ProjectStatus.DEACTIVATED) {
        return "Error: Project is deactivated.";
      }

      if (status === ProjectStatus.RUNNING_BUT_NOT_WORKING) {
        return "Error: Project is offline.";
      }

      if (status === ProjectStatus.NOT_READY) {
        return "Error: Project is not ready yet.";
      }

      if (project.options.options[project.selected]?.nodes_count === 0) {
        return "Error: No region for this project.";
      }

      if (isAnyJobWithError && projectErrorString) {
        return projectErrorString;
      }

      if (isAnyJobInProgress && projectStatusString) {
        return projectStatusString;
      }
    }

    if (project.isEmpty) {
      return "Error: No project found.";
    }

    return undefined;
  }, [
    project,
    isAnyJobInProgress,
    isAnyJobWithError,
    projectStatusString,
    projectErrorString,
    status,
  ]);

  const databaseError = useMemo(() => {
    if (database.isError) {
      return "Network error: Unable to fetch databases.";
    }

    if (database.selected) {
      switch (database.options.options[database.selected]?.status) {
        case 0:
          return "Error: File is not a valid SQLite database.";
        case 2:
          return "Error: Database is disabled.";
        case 3:
          return "Error: Database in maintenance mode.";
        case 4:
          return "Error: Database is in error state.";
      }
    }

    if (database.isEmpty) {
      return "Error: No database found for the selected project.";
    }

    return undefined;
  }, [database]);

  const userError = useMemo(() => {
    if (user.isError) {
      return "Network error: Unable to fetch users.";
    }

    return undefined;
  }, [user]);

  const apiKeyError = useMemo(() => {
    if (apiKey.isError) {
      return "Network error: Unable to fetch API keys.";
    }

    if (apiKey.selected) {
      const expirationDate =
        apiKey.options.options[apiKey.selected]?.expiration_date;
      const currentDate = new Date();

      if (expirationDate && new Date(expirationDate) < currentDate) {
        return "Error: Selected API key is expired.";
      }
    }

    if (apiKey.isEmpty) {
      return "Error: No API key found for the selected user.";
    }

    return undefined;
  }, [apiKey]);

  return {
    errorMessages: {
      project: projectError,
      database: databaseError,
      user: userError,
      apiKey: apiKeyError,
      connectionString:
        connectionString === ""
          ? "Error: Failed to generate connection string."
          : undefined,
    },
    isProjectFailing: status
      ? status !== ProjectStatus.RUNNING_AND_WORKING
      : false,
  };
};
