import { useEditData_v2 } from "@custom-hooks/edit-data";
import { EditDataOpt, EditDataResult } from "@data-types/generic-hook-type";
import { convertDateToUtc0 } from "@lib/iso-utils";

/**
 * Custom hook to handle the creation of an API key for a specific project.
 *
 * @param {EditDataOpt<null>} options - Configuration options for the `useEditData_v2` hook.
 *
 * @returns An object containing:
 * - `createApiKey`: A function to create a new API key for a given project.
 * - `isCreatingApiKey`: A boolean indicating whether the API key creation request is in progress.
 * - `error`: Any error encountered during the API key creation request.
 * - `editedData`: The result of the API key creation request.
 */
export function useCreateApiKey(options: EditDataOpt<null>) {
  const { isMutatingData, isLoading, editData, error, editedData } =
    useEditData_v2(options);

  /**
   * Function to create a new API key for a given project.
   * It sends a `POST` request to create the API key and associates it with a specific project.
   *
   * @param {string} params.projectId - The ID of the project where the API key will be created.
   * @param {string} params.username - The username for whom the API key is being created.
   * @param {string} params.keyName - The name associated with the API key.
   * @param {Date | string | number} [params.expirationDate] - The expiration date for the API key.
   *    It can be a Date object, a string representing a date, or a Unix timestamp.
   *
   * @returns {Promise<EditDataResult<null>>} A promise resolving to the result of the API key creation request.
   */
  const createApiKey = async ({
    projectId,
    username,
    keyName,
    expirationDate,
  }: {
    projectId: string;
    username: string;
    keyName: string;
    expirationDate?: Date | string | number;
  }): Promise<EditDataResult<null>> => {
    return await editData({
      method: "POST",
      endpoint: `/api/projects/${projectId}/apikey/create`,
      endpointCallLocation: "useCreateApiKey",
      serverType: "backend",
      body: {
        username,
        name: keyName,
        expiration_date: expirationDate
          ? convertDateToUtc0(expirationDate)
          : undefined,
      },
      mutateApis: [
        {
          url: `/api/projects/${projectId}/apikey`,
          component: "useGetProjectApiKey",
        },
      ],
    });
  };

  return {
    createApiKey,
    isCreatingApiKey: isLoading || isMutatingData,
    error,
    editedData,
  };
}
