///
// useGetMembers.ts
///

import { useFetchData } from "@custom-hooks/fetch-data";
import { FetchHookResult } from "@data-types/generic-hook-type";
import { Member } from "@data-types/members-types";

/**
 * Custom hook to fetch the list of members for a specified organization.
 *
 * @param {string} params.organizationId - The unique identifier of the organization.
 *
 * @returns {FetchHookResult<Member[]>} The result object containing:
 *   - `data` (Member[] | undefined): The list of organization members, or `undefined` if not available.
 *   - UI state indicators (`isLoading`, `isValidating`, etc.).
 *   - Functions to manage fetched data.
 */
export function useGetMembers({
  organizationId,
}: {
  organizationId: string;
}): FetchHookResult<Member[]> {
  return useFetchData<Member[]>({
    fetchCondition: organizationId !== "",
    source: "dashboard",
    swrKey: {
      url: `/api/organizations/${organizationId}/members`,
      component: "useGetMembers",
    },
    options: {
      revalidateOnFocus: true,
    },
  });
}
