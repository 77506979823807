//
// useCollapseLayout.tsx
//

import { useCustomRouter } from "@custom-hooks/useCustomRouter";
import { useEffect, useState } from "react";
export const useCollapseLayout = () => {
  // Extracts the current path with custom router hook
  const {
    pathname
  } = useCustomRouter();
  const [shouldCollapse, setShouldCollapse] = useState(false);
  useEffect(() => {
    // Define all conditions that should collapse the layout
    const collapseConditions = [pathname === "/organizations/create"];
    setShouldCollapse(collapseConditions.some(Boolean));
  }, [pathname]);
  return shouldCollapse;
};