import { useGetCurrentOrganizationId, useGetOrganization } from "@custom-hooks/organizations";
import { useGetUserSession } from "@custom-hooks/user";
import { RemoveMemberDialog } from "@tw-components/members/RemoveMemberDialog";
import { GenericInput, GenericSideSheetWithForm } from "@tw-components/ui/iso";
import { useState } from "react";
import { DeleteOrganizationButtonWithPermissionsGuard } from "./DeleteOrganizationButtonWithPermissionsGuard";
import { DeleteOrganizationDialog } from "./DeleteOrganizationDialog";
import { LeaveOrganizationButtonWithPermissionsGuard } from "./LeaveOrganizationButtonWithPermissionsGuard";

/**
 * Props for OrganizationSettingsSideSheet component.
 *
 * @property {boolean} isOpen - Determines whether the side sheet is open.
 * @property {() => void} onClose - Callback function to close the side sheet.
 */
type OrganizationSettingsSideSheetProps = {
  isOpen: boolean;
  onClose: () => void;
};

/**
 * OrganizationSettingsSideSheet component provides workspace settings UI.
 *
 * Displays the workspace name, allows the user to leave the organization,
 * and provides an option to delete the organization if they have the necessary permissions.
 *
 * @param {OrganizationSettingsSideSheetProps} props - Component props.
 *
 * @returns {JSX.Element} The rendered component.
 */
export function OrganizationSettingsSideSheet({
  isOpen,
  onClose
}: OrganizationSettingsSideSheetProps): JSX.Element {
  const [showRemoveMemberDialog, setShowRemoveMemberDialog] = useState(false);
  const [showDeleteOrganizationDialog, setShowDeleteOrganizationDialog] = useState(false);
  const organizationId = useGetCurrentOrganizationId();
  const organization = useGetOrganization(organizationId || "");
  const {
    data: session
  } = useGetUserSession();
  return <>
      <GenericSideSheetWithForm<{}> initialValues={{}} validateOnMount={true} onSubmit={onClose} sideSheetProps={() => {
      return {
        onClose: onClose,
        open: isOpen,
        title: "Workspace Settings",
        size: "lg",
        buttonLayoutType: "row",
        submitButtonProps: {
          label: "Close",
          variant: "primary",
          onClick: onClose,
          size: "medium"
        },
        body: <div className="tw-flex tw-flex-col tw-gap-6">
                <GenericInput label="Workspace Name" sizeVar="small" disabled={true} value={organization?.name} />

                <div className="tw-h-[1px] tw-w-full tw-bg-interface-divider-light dark:tw-bg-interface-divider-dark" />

                <LeaveOrganizationButtonWithPermissionsGuard onClick={() => {
            onClose();
            setShowRemoveMemberDialog(true);
          }} />

                <DeleteOrganizationButtonWithPermissionsGuard onClick={() => {
            onClose();
            setShowDeleteOrganizationDialog(true);
          }} />
              </div>
      };
    }} data-sentry-element="GenericSideSheetWithForm" data-sentry-source-file="OrganizationSettingsSideSheet.tsx" />

      <DeleteOrganizationDialog organizationId={organizationId || ""} isOpen={showDeleteOrganizationDialog} onClose={() => setShowDeleteOrganizationDialog(false)} data-sentry-element="DeleteOrganizationDialog" data-sentry-source-file="OrganizationSettingsSideSheet.tsx" />

      <RemoveMemberDialog isOpen={showRemoveMemberDialog} onClose={() => setShowRemoveMemberDialog(false)} member={{
      userId: session?.user.id ?? "",
      name: session?.user.name ?? ""
    }} data-sentry-element="RemoveMemberDialog" data-sentry-source-file="OrganizationSettingsSideSheet.tsx" />
    </>;
}