//
// useUserPermissions.ts
//

import { UserPermissions } from "@data-types/role-based-access-control-types";
import { useHasOrganizationPermission } from "./useHasOrganizationPermission";

/**
 * Hook to retrieve all organization-level permissions the user has.
 *
 * @returns {UserPermissions} An object containing permission-related booleans.
 */
export function useUserPermissions(): UserPermissions {
  return {
    canInviteMembers: useHasOrganizationPermission([
      "organization.member.invite",
    ]),
    canViewMembers: useHasOrganizationPermission(["organization.member.view"]),
    canUpdateMembers: useHasOrganizationPermission([
      "organization.member.update",
    ]),
    canManageRoles: useHasOrganizationPermission([
      "organization.member.role.update",
    ]),
    canDeleteMembers: useHasOrganizationPermission([
      "organization.member.delete",
    ]),
    canCreateProjects: useHasOrganizationPermission([
      "organization.project.create",
    ]),
    canUpdateProjects: useHasOrganizationPermission([
      "organization.project.update",
    ]),
    canDeleteProjects: useHasOrganizationPermission([
      "organization.project.delete",
    ]),
    canLeaveOrganization: useHasOrganizationPermission(["organization.leave"]),
    canUpdateOrganization: useHasOrganizationPermission([
      "organization.update",
    ]),
    canDeleteOrganization: useHasOrganizationPermission([
      "organization.delete",
    ]),
    canCreateSubscription: useHasOrganizationPermission([
      "organization.subscription.create",
    ]),
    canUpdateSubscriptionItems: useHasOrganizationPermission([
      "organization.subscription.items.update",
    ]),
    canDeleteSubscription: useHasOrganizationPermission([
      "organization.subscription.delete",
    ]),
    canViewBillingDetails: useHasOrganizationPermission([
      "organization.subscription.billing.view",
    ]),
    canUpdateBillingDetails: useHasOrganizationPermission([
      "organization.subscription.billing.update",
    ]),
  };
}
