///
// useGetPendingInvitations.ts
///

import { useFetchData } from "@custom-hooks/fetch-data";
import { FetchHookResult } from "@data-types/generic-hook-type";
import { Invitation } from "@data-types/members-types";

/**
 * Custom hook to fetch pending invitations for a given organization.
 *
 * @param {string} params.organizationId - The ID of the organization.
 *
 * @returns {FetchHookResult<Invitation[]>} The fetched pending invitations and fetch details.
 */
export function useGetPendingInvitations({
  organizationId,
}: {
  organizationId: string;
}): FetchHookResult<Invitation[]> {
  const { data, ...fetchDetails } = useFetchData<Invitation[]>({
    fetchCondition: organizationId !== "",
    source: "dashboard",
    swrKey: {
      url: `/api/organizations/${organizationId}/invitations`,
      component: "useGetPendingInvitations",
    },
    options: {
      revalidateOnFocus: true,
    },
  });

  const pendingInvitations = data
    ?.filter((invitation) => invitation.status === "pending")
    .sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );

  return {
    data: pendingInvitations,
    ...fetchDetails,
  };
}
