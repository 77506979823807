//react
import { useEffect, useState } from "react";
//next-auth
import { signIn } from "next-auth/react";
//swr
import { useSWRConfig } from "swr";
//css library
import styled from "@emotion/styled";
//yup
import * as Yup from "yup";
//formik
import { Formik } from "formik";
//mui components
import Box from "@mui/material/Box";
import MuiButton from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import MuiLinearProgress from "@mui/material/LinearProgress";
import { useTheme } from "@mui/material/styles";
import MuiTextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { spacing } from "@mui/system";
const LinearProgress = styled(MuiLinearProgress)(spacing);
const Button = styled(MuiButton)(spacing);
const TextField = styled(MuiTextField)(spacing);
//sqlitecloud components
import Avatar from "@components/GenericElements/Avatar";
import CustomSwitch from "@components/GenericElements/CustomSwitch";

//sqlitecloud constant
const EXPERIMENTAL_COMPANIES_ID =
  process.env.NEXT_PUBLIC_EXPERIMENTAL_COMPANIES_ID;
const experimentalCompaniesId = EXPERIMENTAL_COMPANIES_ID.split(",");
//sqlitecloud lib
import { fetchApiRoute } from "@lib/client-side";
//sqlitecloud context
//sqlitecloud hooks
import { useSetError } from "@custom-hooks/useSetError";
import { useSetSnackNotification } from "@custom-hooks/useSetSnackNotification";
//sqlitecloud componenets
import { useGetCurrentOrganizationId } from "@custom-hooks/organizations";
import {
  useDisconnectAccountProvider,
  useGetUserAccountProfile,
  useGetUserSession,
} from "@custom-hooks/user";
import InputWithCustomLabel from "@generic-components/Form/InputWithCustomLabel";
import ModalContent from "@generic-components/ModalContent";
import { useAppState } from "@state/AppStateProvider";

function Profile({ callback = undefined }) {
  //get theme
  const theme = useTheme();
  //get user
  const { data: session } = useGetUserSession();
  //get user github account profile
  const {
    data: githubAccountProfile,
    isLoading: githubAccountIsLoading,
    isError: githubAccountError,
  } = useGetUserAccountProfile("github");
  //get user github account profile
  const {
    data: googleAccountProfile,
    isLoading: googleAccountIsLoading,
    isError: googleAccountError,
  } = useGetUserAccountProfile("google");

  const { isDisconnectingAccountProvider, disconnectAccountProvider } =
    useDisconnectAccountProvider({});

  //method call to disconnect github account
  const disconnectProvider = async (provider) => {
    await disconnectAccountProvider({ provider });
  };

  //get simulation generic user from context
  const { simulateGenericUser, setSimulateGenericUser } = useAppState();
  //check if it is a user that belog to a company id enabled for experimental features
  let companyId = useGetCurrentOrganizationId();
  let testCompany = experimentalCompaniesId.includes(companyId);

  //handle change in enabling state
  const handleSimulateGenericUserState = () => {
    if (session?.userId && companyId) {
      setSimulateGenericUser(!simulateGenericUser);
      //save in local storage setSimulateGenericUser state
      var jsonData = JSON.stringify(!simulateGenericUser);
      localStorage.setItem(`${companyId}-${session.userId}`, jsonData);
    }
  };
  //swr
  const { mutate } = useSWRConfig();
  //handling open form
  const handleClose = () => {
    setUpdatingStatus(false);
    setUpdatingError(null);
    if (callback) callback();
  };
  //handle show/hide password
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  //handle mail submitting error
  const [updatingError, setUpdatingError] = useState(null);
  useSetError(updatingError);
  useEffect(() => {
    if (updatingError) {
      handleClose();
    }
  }, [updatingError]);
  //get method to update snack notification
  const { createSnackNotification } = useSetSnackNotification();
  //handle positive submission
  const [updatingStatus, setUpdatingStatus] = useState(false);
  useEffect(() => {
    if (updatingStatus) {
      const UpdateFeedback = ({}) => {
        return (
          <Typography variant="14px-reg">
            Your profile has been updated successfully!
          </Typography>
        );
      };
      const newUpdate = <UpdateFeedback />;
      createSnackNotification({
        type: "info-2",
        component: newUpdate,
      });
      handleClose();
    }
  }, [updatingStatus]);
  //data condition
  const showForm = session;

  const providerLoader =
    googleAccountIsLoading ||
    githubAccountIsLoading ||
    isDisconnectingAccountProvider;
  //render
  return (
    <>
      <ModalContent actionType="none" title={"Update your Profile"}>
        <Formik
          initialValues={{
            name: session && session.user?.name ? session.user.name : "...",
            email: session ? session.user.email : "...",
            password: "",
            confirmPassword: "",
            submit: false,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().max(255).required("Name is required"),
            email: Yup.string()
              .email("Must be a valid email")
              .max(255)
              .required("Email is required"),
            password: Yup.string()
              .min(8, "Password must be at least 8 characters")
              .matches(
                /[a-z]/,
                "Password must contain at least one lowercase character"
              )
              .matches(
                /[A-Z]/,
                "Password must contain at least one uppercase character"
              )
              .matches(/[0-9]/, "Password must contain at least one number"),
            confirmPassword: Yup.string().when("password", {
              is: (val) => (val && val.length > 0 ? true : false),
              then: () =>
                Yup.string()
                  .oneOf(
                    [Yup.ref("password")],
                    "Both password need to be the same"
                  )
                  .required("Confirm password is required"),
            }),
          })}
          onSubmit={async (
            values,
            { setErrors, setStatus, setSubmitting, resetForm }
          ) => {
            let body = {
              email: values.email,
              name: values.name,
            };
            if (values.password) {
              body.password = values.password;
            }
            try {
              const opt = {
                method: "POST",
                endpoint: `/api/userUpdate`,
                endpointCallLocation: "Profile index.js",
                body: body,
              };
              const res = await fetchApiRoute(opt);
              resetForm();
              setStatus({ success: true });
              setSubmitting(false);
              setUpdatingStatus(true);
              mutate(["/api/auth/session", "useGetUserSession"]);
            } catch (error) {
              setStatus({ success: false });
              setErrors({ submit: error });
              setSubmitting(false);
              setUpdatingError(error);
              setUpdatingStatus(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            status,
          }) => {
            const disableSend =
              isSubmitting || Object.keys(errors).length !== 0;
            return (
              <>
                {(!showForm || isSubmitting) && <LinearProgress m={2} />}
                {showForm && !isSubmitting && !status && (
                  <Grid item>
                    <form noValidate onSubmit={handleSubmit}>
                      {testCompany && (
                        <Grid container flexDirection mb={"0.75rem"}>
                          <InputWithCustomLabel
                            label="Experimental features"
                            enableMinWidth={false}
                            minWidth="fit-content"
                            flexGrow={0}
                          >
                            <CustomSwitch
                              checked={!simulateGenericUser}
                              onChange={handleSimulateGenericUserState}
                            />
                          </InputWithCustomLabel>
                        </Grid>
                      )}
                      <Grid
                        container
                        flexDirection={"column"}
                        rowGap={"1.75rem"}
                      >
                        <Divider />
                        <TextField
                          type="email"
                          name="email"
                          label="Email address"
                          value={values.email}
                          error={Boolean(touched.email && errors.email)}
                          fullWidth
                          helperText={touched.email && errors.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          // disabled={isSubmitting}
                          sx={{ pointerEvents: "none" }}
                        />
                        <TextField
                          type="text"
                          name="name"
                          label="Name"
                          value={values.name}
                          error={Boolean(touched.name && errors.name)}
                          fullWidth
                          helperText={touched.name && errors.name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          disabled={isSubmitting}
                        />
                        <Divider />
                        <Box>
                          <Typography
                            fontSize={"0.875rem"}
                            fontWeight={600}
                            sx={{ paddingBottom: "0.75rem" }}
                          >
                            {" "}
                            Authentication
                          </Typography>
                          {providerLoader && <LinearProgress mb={2} />}
                          <Box>
                            {/* GITHUB */}
                            <a
                              href={
                                githubAccountProfile &&
                                githubAccountProfile.profile &&
                                githubAccountProfile.profile.html_url
                              }
                              target="_blank"
                              style={{ width: "fit-content" }}
                            >
                              <Box
                                width={"fit-content"}
                                justifyContent={"flex-start"}
                                alignItems={"center"}
                                gap={"0.75rem"}
                                display={"inline-flex"}
                              >
                                <Box
                                  width={"2.5rem"}
                                  height={"2.5rem"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  display={"flex"}
                                >
                                  <Avatar
                                    width="2.5rem"
                                    height="2.5rem"
                                    img={
                                      githubAccountProfile &&
                                      githubAccountProfile.profile &&
                                      githubAccountProfile.profile.avatar_url
                                    }
                                  />
                                </Box>
                                <Box
                                  width={"7.188rem"}
                                  flexDirection={"column"}
                                  justifyContent={"flex-start"}
                                  alignItems={"flex-start"}
                                  display={"inline-flex"}
                                >
                                  <Typography variant={"12px-demi"}>
                                    GitHub
                                  </Typography>
                                  <Typography variant={"12px-reg"}>
                                    {githubAccountProfile &&
                                      githubAccountProfile.profile &&
                                      (githubAccountProfile.profile.name ??
                                        githubAccountProfile.profile.login)}
                                  </Typography>
                                </Box>
                                {((githubAccountProfile &&
                                  githubAccountProfile.profile) ||
                                  githubAccountError) && (
                                  <Button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      disconnectProvider("github");
                                    }}
                                    size="macro-action-secondary"
                                    color="secondary"
                                  >
                                    Disconnect GitHub
                                  </Button>
                                )}
                                {githubAccountProfile &&
                                  !githubAccountProfile.profile && (
                                    <Button
                                      sx={{ width: "fit-content" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        signIn("github", {
                                          redirectTo: "/?profile=open",
                                        });
                                      }}
                                      size="macro-action-primary"
                                      color="secondary"
                                    >
                                      Connect GitHub
                                    </Button>
                                  )}
                              </Box>
                            </a>
                          </Box>
                          {/* GOOGLE */}
                          <Box mt={6}>
                            <Box
                              width={"fit-content"}
                              justifyContent={"flex-start"}
                              alignItems={"center"}
                              gap={"0.75rem"}
                              display={"inline-flex"}
                            >
                              <Box
                                width={"2.5rem"}
                                height={"2.5rem"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                display={"flex"}
                              >
                                <Avatar
                                  width="2.5rem"
                                  height="2.5rem"
                                  img={
                                    googleAccountProfile &&
                                    googleAccountProfile.profile &&
                                    googleAccountProfile.profile.picture
                                  }
                                />
                              </Box>
                              <Box
                                width={"7.188rem"}
                                flexDirection={"column"}
                                justifyContent={"flex-start"}
                                alignItems={"flex-start"}
                                display={"inline-flex"}
                              >
                                <Typography variant={"12px-demi"}>
                                  Google
                                </Typography>
                                <Typography variant={"12px-reg"}>
                                  {googleAccountProfile &&
                                    googleAccountProfile.profile &&
                                    googleAccountProfile.profile.name}
                                </Typography>
                              </Box>
                              {((googleAccountProfile &&
                                googleAccountProfile.profile) ||
                                googleAccountError) && (
                                <Button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    disconnectProvider("google");
                                  }}
                                  size="macro-action-secondary"
                                  color="secondary"
                                >
                                  Disconnect Google
                                </Button>
                              )}
                              {/* Commented out because this could cause a situation where, 
                              if I am logged into the browser with the email mike@gmail.com, 
                              but the SQLiteCloud account is registered with mike@acme.com, 
                              even though the emails are different, they get associated. This is not desirable  
                              */}
                              {googleAccountProfile &&
                                !googleAccountProfile.profile && (
                                  <Button
                                    sx={{ width: "fit-content" }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      signIn("google", {
                                        redirectTo: "/?profile=open",
                                      });
                                    }}
                                    size="macro-action-primary"
                                    color="secondary"
                                  >
                                    Connect Google
                                  </Button>
                                )}
                            </Box>
                          </Box>
                        </Box>
                        <Divider />

                        {/* <FormControl fullWidth variant="outlined" error={Boolean(touched.password && errors.password)}>
                          <InputLabel htmlFor="password">Password</InputLabel>
                          <OutlinedInput
                            id="password"
                            name="password"
                            label="Password"
                            type={showPassword ? 'text' : 'password'}
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={isSubmitting}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          {touched.password && <FormHelperText id="component-error-text">{touched.password}</FormHelperText>}
                          {errors.password && <FormHelperText id="component-error-text">{errors.password}</FormHelperText>}
                        </FormControl>
                        <TextField
                          type={showPassword ? 'text' : 'password'}
                          name="confirmPassword"
                          label="Confirm password"
                          value={values.confirmPassword}
                          error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                          fullWidth
                          helperText={touched.confirmPassword && errors.confirmPassword}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          disabled={isSubmitting}
                        /> */}
                      </Grid>
                    </form>
                  </Grid>
                )}
                <Grid
                  container
                  flexDirection={"row"}
                  justifyContent={"center"}
                  mt={"1.125rem"}
                >
                  <Button
                    disabled={disableSend || isSubmitting || status}
                    onClick={handleSubmit}
                    variant="modal-primary"
                  >
                    Update
                  </Button>
                </Grid>
              </>
            );
          }}
        </Formik>
      </ModalContent>
    </>
  );
}

export default Profile;
