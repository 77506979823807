import { useSubscriptionDetails, useUpdateSubscription } from "@custom-hooks/billing";
import { useGetMembersAndInvitationsCount, useRemoveMember } from "@custom-hooks/members";
import { useGetCurrentOrganizationId, useGetOrganization } from "@custom-hooks/organizations";
import { useGetUserSession } from "@custom-hooks/user";
import { AddonActionNames, AddonLookupKey } from "@data-types/billing-types";
import { Member } from "@data-types/members-types";
import LeaveIcon from "@layouts/svg-icon/leave-icon.svg";
import { FREE_MEMBERS_PER_ORGANIZATION_LIMIT } from "@lib/billing/constants";
import { GenericDeleteDialog, MessageType } from "@tw-components/ui/iso";
import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";

/**
 * Props for the RemoveMemberDialog component.
 *
 * @property {boolean} isOpen - Determines whether the dialog is open.
 * @property {() => void} onClose - Callback function to close the dialog.
 * @property {string} member - The member being removed.
 */
type RemoveMemberDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  member: Pick<Member, "name" | "userId">;
};

/**
 * A dialog component for removing a member from an organization.
 * If the user being removed is the current user, the dialog will function as a "Leave workspace" confirmation.
 *
 * @param {RemoveMemberDialogProps} props - The props for the component.
 *
 * @returns {JSX.Element} The RemoveMemberDialog component.
 */
export function RemoveMemberDialog({
  isOpen,
  onClose,
  member
}: RemoveMemberDialogProps): JSX.Element {
  const organizationId = useGetCurrentOrganizationId();
  const organization = useGetOrganization(organizationId || "");
  const {
    removeMember
  } = useRemoveMember({});
  const {
    data: session
  } = useGetUserSession();
  const router = useRouter();
  const {
    updateSubscription
  } = useUpdateSubscription({});
  const {
    totalMembersAndInvitationsCount
  } = useGetMembersAndInvitationsCount({
    organizationId: organizationId || ""
  });
  const posthog = usePostHog();
  const {
    hasSubscription
  } = useSubscriptionDetails();
  const isCurrentUser = session?.userId === member.userId;
  const errorMessage = {
    type: MessageType.Error,
    title: isCurrentUser ? "Failed to leave workspace" : "Failed to remove member",
    description: isCurrentUser ? "An error occurred while leaving workspace. Please try again later. If the issue persists, contact us for assistance." : "An error occurred while removing member. Please try again later. If the issue persists, contact us for assistance."
  };
  useEffect(() => {
    if (isOpen) {
      posthog.capture("remove_member_dialog_opened", {
        has_subscription: hasSubscription,
        organization_id: organizationId
      });
    }
  }, [isOpen]);
  return <GenericDeleteDialog open={isOpen} onClose={onClose} preventInitialOutsideClose={true} title={isCurrentUser ? "Leave workspace?" : "Remove member?"} description={`This action is permanent and cannot be undone.`} deleteButton={{
    onClick: async setStatus => {
      if (totalMembersAndInvitationsCount) {
        if (totalMembersAndInvitationsCount > FREE_MEMBERS_PER_ORGANIZATION_LIMIT) {
          const updateSubscriptionResult = await updateSubscription({
            newAddons: [{
              addon: AddonLookupKey.MEMBER,
              quantity: -1,
              action: {
                name: AddonActionNames.DELETE,
                data: {
                  type: "current_member",
                  userId: member.userId
                }
              }
            }]
          });
          if (updateSubscriptionResult.success) {
            if (isCurrentUser) {
              router.push("/");
            }
            onClose();
          } else {
            setStatus(errorMessage);
          }
        } else {
          const removeMemberResult = await removeMember({
            organizationId: organizationId || "",
            userId: member.userId,
            isCurrentUser,
            session
          });
          if (removeMemberResult.success) {
            if (isCurrentUser) {
              router.push("/");
            }
            onClose();
            posthog.capture("member_removed", {
              has_subscription: hasSubscription,
              organization_id: organizationId
            });
          } else {
            setStatus(errorMessage);
          }
        }
      } else {
        setStatus(errorMessage);
      }
    },
    label: isCurrentUser ? "Leave" : "Remove",
    icon: isCurrentUser ? <LeaveIcon /> : undefined
  }} variant={{
    type: "with_confirmation",
    firstConfirmation: {
      label: isCurrentUser ? `To verify, type “${organization?.name ?? ""}” below to leave this workspace` : `To verify, type “${member.name}” below to remove this member from your workspace`,
      text: isCurrentUser ? organization?.name ?? "" : member.name,
      name: isCurrentUser ? "Workspace name" : "User name"
    }
  }} data-sentry-element="GenericDeleteDialog" data-sentry-component="RemoveMemberDialog" data-sentry-source-file="RemoveMemberDialog.tsx" />;
}