import { withPermissionsGuard } from "@hoc/withPermissionsGuard";
import { Button, StandardButtonProps } from "@tw-components/ui/iso";

/**
 * A button that allows the user to open the stripe portal.
 *
 * @param {StandardButtonProps} props - The props for the button component.
 *
 * @returns {JSX.Element} The JSX code for rendering the button.
 */
function ViewStripeBillingPortalButton(props: StandardButtonProps): JSX.Element {
  return <Button size="medium" {...props} data-sentry-element="Button" data-sentry-component="ViewStripeBillingPortalButton" data-sentry-source-file="ViewStripeBillingPortalButtonWithPermissionsGuard.tsx" />;
}

/**
 * A higher-order component that wraps the ViewStripeBillingPortalButton with permission checks.
 * If the user lacks the required permissions, button won't be rendered.
 *
 * @returns {JSX.Element} The JSX code for rendering the button with permissions guard.
 */
export const ViewStripeBillingPortalButtonWithPermissionsGuard = ({
  shouldHideButtonIfNoPermission,
  ...props
}: {
  shouldHideButtonIfNoPermission: boolean;
} & StandardButtonProps) => {
  const WithoutTooltip = withPermissionsGuard({
    Component: ViewStripeBillingPortalButton,
    permissions: ["canUpdateBillingDetails"]
  });
  const WithTooltip = withPermissionsGuard({
    Component: ViewStripeBillingPortalButton,
    permissions: ["canUpdateBillingDetails"],
    NoPermissionComponent: <ViewStripeBillingPortalButton {...props} tooltipOnHover={{
      shouldDisableButton: true,
      text: "You don't have permissions to update billing details.",
      alignment: "topRight"
    }} />
  });
  if (shouldHideButtonIfNoPermission) {
    return <WithoutTooltip {...props} />;
  } else {
    return <WithTooltip {...props} />;
  }
};