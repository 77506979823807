import { useEditData_v2 } from "@custom-hooks/edit-data";
import { EditDataOpt, EditDataResult } from "@data-types/generic-hook-type";

/**
 * Custom hook to delete an organization by making an API request.
 *
 * @param {EditDataOpt<null>} options - Configuration options for the hook.
 *
 * @returns {Function} return.deleteOrganization - Function to delete an organization.
 * @returns {boolean} return.isDeletingOrganization - Indicates if the deletion is in progress.
 * @returns {Error | null} return.error - Error object if the request fails.
 */
export function useDeleteOrganization(options: EditDataOpt<null>) {
  const { isMutatingData, isLoading, editData, error } = useEditData_v2({
    ...options,
    stopGlobalError: options?.stopGlobalError ?? true,
  });

  /**
   * Deletes an organization by making a `DELETE` API request.
   *
   * @param {string} params.organizationId - The ID of the organization to delete.
   *
   * @returns {Promise<EditDataResult<null>>} Resolves when the deletion request is completed.
   */
  const deleteOrganization = async ({
    organizationId,
  }: {
    organizationId: string;
  }): Promise<EditDataResult<null>> => {
    return await editData({
      method: "DELETE",
      endpoint: `/api/organizations/${organizationId}`,
      endpointCallLocation: "useDeleteOrganization",
      serverType: "backend",
      mutateApis: [
        { url: "/api/auth/session", component: "useGetUserSession" },
      ],
    });
  };

  return {
    deleteOrganization,
    isDeletingOrganization: isLoading || isMutatingData,
    error,
  };
}
