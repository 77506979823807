//
// FullBrandMark.tsx
//

import SqliteCloudFullBrandMarkDark from "@layouts/svg-brand/sqlitecloud-full-brand-logo-dark.svg";
import SqliteCloudFullBrandMarkLight from "@layouts/svg-brand/sqlitecloud-full-brand-logo-light.svg";
export function FullBrandMark({
  size = 256
}: {
  size?: number;
}) {
  // Render component
  return <>
      <SqliteCloudFullBrandMarkLight width={size} height={size * 0.34} className="tw-dark:tw-hidden tw-block" data-sentry-element="SqliteCloudFullBrandMarkLight" data-sentry-source-file="FullBrandMark.tsx" />
      <SqliteCloudFullBrandMarkDark width={size} height={size * 0.34} className="tw-dark:tw-block tw-hidden" data-sentry-element="SqliteCloudFullBrandMarkDark" data-sentry-source-file="FullBrandMark.tsx" />
    </>;
}