import { useSubscriptionDetails } from "@custom-hooks/billing";
import { withPermissionsGuard } from "@hoc/withPermissionsGuard";
import RecycleIcon from "@layouts/svg-icon/recycle-icon.svg";
import { Button, StandardButtonPropsCustomVariant } from "@tw-components/ui/iso";

/**
 * Props for the DeleteOrganizationButton component.
 */
type DeleteOrganizationButtonProps = Omit<StandardButtonPropsCustomVariant, "variant" | "label" | "customStyles">;

/**
 * A button that allows the user to open the Delete Organization Dialog.
 *
 * @param {DeleteOrganizationButtonProps} props - The props for the button component.
 *
 * @returns {JSX.Element} The JSX code for rendering the button.
 */
function DeleteOrganizationButton(props: DeleteOrganizationButtonProps): JSX.Element {
  return <Button variant="custom" customStyles={{
    base: ["tw-flex tw-flex-row tw-gap-2 tw-text-13px-medium"],
    enabled: ["tw-text-semantics-error-light dark:tw-text-semantics-error-dark"],
    disabled: ["tw-text-interface-gray-light dark:tw-text-interface-gray-dark"]
  }} {...props} data-sentry-element="Button" data-sentry-component="DeleteOrganizationButton" data-sentry-source-file="DeleteOrganizationButtonWithPermissionsGuard.tsx">
      <RecycleIcon data-sentry-element="RecycleIcon" data-sentry-source-file="DeleteOrganizationButtonWithPermissionsGuard.tsx" />

      <span>Delete workspace</span>
    </Button>;
}

/**
 * A higher-order component that wraps the DeleteOrganizationButton with permission checks.
 * If the user lacks the required permissions, the button is not rendered.
 *
 * @param {DeleteOrganizationButtonProps} props - The props for the button component.
 *
 * @returns {JSX.Element} The JSX code for rendering the button with permissions guard.
 */
export const DeleteOrganizationButtonWithPermissionsGuard = (props: DeleteOrganizationButtonProps) => {
  const {
    hasSubscription
  } = useSubscriptionDetails();
  const WithPermissionGuard = withPermissionsGuard({
    Component: DeleteOrganizationButton,
    permissions: hasSubscription ? ["canDeleteOrganization", "canDeleteSubscription"] : ["canDeleteOrganization"]
  });
  return <WithPermissionGuard {...props} data-sentry-element="WithPermissionGuard" data-sentry-component="DeleteOrganizationButtonWithPermissionsGuard" data-sentry-source-file="DeleteOrganizationButtonWithPermissionsGuard.tsx" />;
};