import { useSubscriptionDetails } from "@custom-hooks/billing";
import { withPermissionsGuard } from "@hoc/withPermissionsGuard";
import { MissingPermissionsToCreateSubscriptionDialog } from "@tw-components/billing/MissingPermissionsToCreateSubscriptionDialogProps";
import { Button, StandardButtonProps } from "@tw-components/ui/iso";
import { useState } from "react";

/**
 * Props for the UpgradeProjectButton component.
 */
type UpgradeProjectButtonProps = Omit<StandardButtonProps, "variant">;

/**
 * A button that allows the user to upgrade a Pro Project.
 *
 * @param {UpgradeProjectButtonProps} props - The props for the button component.
 *
 * @returns {JSX.Element} The JSX code for rendering the button.
 */
function UpgradeProjectButton(props: UpgradeProjectButtonProps): JSX.Element {
  return <Button variant="primary" label="Upgrade to Pro" size="small" {...props} data-sentry-element="Button" data-sentry-component="UpgradeProjectButton" data-sentry-source-file="UpgradeProjectButtonWithPermissionsGuard.tsx" />;
}

/**
 * A higher-order component that wraps the UpgradeProjectButton with different permission checks depending on subscription status.
 *
 * @param {CreateProProjectButtonProps} props - The props for the UpgradeProjectButton component.
 *
 * @returns {JSX.Element} The JSX code for rendering the appropriate button based on permissions and subscription status.
 */
export const UpgradeProjectButtonWithPermissionsGuard = (props: UpgradeProjectButtonProps) => {
  const {
    hasSubscription
  } = useSubscriptionDetails();
  const [isMissingPermissionsDialogOpen, setIsMissingPermissionsDialogOpen] = useState(false);
  const UpgradeProjectButtonWithSubscription = withPermissionsGuard({
    Component: UpgradeProjectButton,
    permissions: ["canCreateProjects", "canUpdateSubscriptionItems"],
    NoPermissionComponent: <UpgradeProjectButton {...props} tooltipOnHover={{
      shouldDisableButton: true,
      text: "You don't have permissions to upgrade projects.",
      alignment: "topRight"
    }} />
  });
  const UpgradeProjectButtonWithoutSubscription = withPermissionsGuard({
    Component: UpgradeProjectButton,
    permissions: ["canCreateProjects", "canCreateSubscription"],
    NoPermissionComponent: <>
        <UpgradeProjectButton {...props} onClick={() => setIsMissingPermissionsDialogOpen(true)} />

        <MissingPermissionsToCreateSubscriptionDialog isOpen={isMissingPermissionsDialogOpen} onClose={() => setIsMissingPermissionsDialogOpen(false)} />
      </>
  });
  if (hasSubscription) {
    return <UpgradeProjectButtonWithSubscription {...props} />;
  } else {
    return <UpgradeProjectButtonWithoutSubscription {...props} />;
  }
};