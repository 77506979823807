import { useEditData_v2 } from "@custom-hooks/edit-data";
import { ApiKey } from "@data-types/api-key-types";
import { EditDataOpt, EditDataResult } from "@data-types/generic-hook-type";
import { convertDateToUtc0 } from "@lib/iso-utils";

/**
 * Custom hook to handle regenerating an API key for a specific project.
 *
 * @param {EditDataOpt<null>} options - Configuration options for the `useEditData_v2` hook.
 *
 * @returns An object containing:
 * - `regenerateApiKey`: A function to regenerate an API key for a given project.
 * - `isRegeneratingApiKey`: A boolean indicating whether the API key regeneration request is in progress.
 * - `error`: Any error encountered during the API key regeneration request.
 */
export function useRegenerateApiKey(options: EditDataOpt<null>) {
  const { isMutatingData, isLoading, editData, error } =
    useEditData_v2(options);

  /**
   * Function to regenerate an existing API key for a given project.
   *
   * @param {string} params.projectId - The ID of the project where the API key is being regenerated.
   * @param {ApiKey} params.apiKey - The existing API key object that needs to be regenerated.
   * @param {Date | string | number} [params.expirationDate] - The new expiration date for the API key.
   *    It can be a Date object, a string representing a date, or a Unix timestamp.
   *
   * @returns {Promise<EditDataResult<null>>} A promise resolving to the result of the API key regeneration request.
   */
  const regenerateApiKey = async ({
    projectId,
    apiKey,
    expirationDate,
  }: {
    projectId: string;
    apiKey: ApiKey;
    expirationDate?: Date | string | number;
  }): Promise<EditDataResult<null>> => {
    return await editData({
      method: "POST",
      endpoint: `/api/projects/${projectId}/apikey/regenerate`,
      endpointCallLocation: "useRegenerateApiKey",
      serverType: "backend",
      body: {
        key: apiKey.key,
        username: apiKey.username,
        name: apiKey.name,
        is_default: apiKey.is_default ? true : undefined,
        expiration_date: expirationDate
          ? convertDateToUtc0(expirationDate)
          : undefined,
      },
      mutateApis: [
        {
          url: `/api/projects/${projectId}/apikey`,
          component: "useGetProjectApiKey",
        },
        { url: `/api/projects/${projectId}`, component: "useGetProjectInfo" },
        {
          url: `/api/projects/${projectId}/nodes`,
          component: "useGetProjectNodes",
        },
      ],
    });
  };

  return {
    regenerateApiKey,
    isRegeneratingApiKey: isLoading || isMutatingData,
    error,
  };
}
