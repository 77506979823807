//
// useHasProjectPermission.ts
//

import { useGetCurrentProjectId } from "@custom-hooks/projects";
import { useGetUserSession } from "@custom-hooks/user";
import { ProjectPermission } from "@data-types/role-based-access-control-types";
import { Authorizations } from "@data-types/user-types";
import { validateUserPermission } from "@lib/role-based-access-control/iso-utils";

/**
 * Hook to check if the user has the required permissions for a specific project.
 *
 * @param requiredPermissions - The list of permissions required.
 * @returns `true` if the user has all required permissions, otherwise `false`.
 */
export function useHasProjectPermission(
  requiredPermissions: ProjectPermission[]
): boolean {
  // Fetch the user session
  const { data: session, hasData } = useGetUserSession();

  // Get actual project ID
  const actualProjectId = useGetCurrentProjectId();

  // If session data is not available, return false
  if (!hasData || !session || !actualProjectId) return false;

  // Retrieve user's authorizations
  const authorizations: Authorizations["projects"] =
    session.authorizations?.projects || {};

  // Try validating the user's permissions
  try {
    return validateUserPermission(
      authorizations,
      requiredPermissions,
      actualProjectId
    );
  } catch {
    return false;
  }
}
