///
// useResendInvitation.ts
///

import { useEditData_v2 } from "@custom-hooks/edit-data";
import { EditDataOpt, EditDataResult } from "@data-types/generic-hook-type";

/**
 * Custom hook for resending invitations to users.
 * Handles the API call to resend an existing invitation.
 *
 * @param {EditDataOpt<null>} options - Options for the hook including mutation configurations.
 *
 * @returns {Function} return.resendInvitation - Function to resend an invitation.
 * @returns {boolean} return.isResendingInvitation - Loading state of the resend operation.
 * @returns {Error | null} return.errorResendingInvitation - Error state, if any.
 */
export function useResendInvitation(options: EditDataOpt<null>) {
  const { isMutatingData, isLoading, editData, error } = useEditData_v2({
    ...options,
    stopGlobalError: options?.stopGlobalError ?? true,
  });

  /**
   * Re-sends an invitation to a user.
   *
   * @param {string} params.organizationId - The ID of the organization.
   * @param {string} params.invitationId - The ID of the invitation.
   *
   * @returns {Promise<EditDataResult<null>>} - The result of the resend request.
   */
  const resendInvitation = async ({
    organizationId,
    invitationId,
  }: {
    organizationId: string;
    invitationId: string;
  }): Promise<EditDataResult<null>> => {
    return await editData({
      method: "POST",
      endpoint: `/api/organizations/${organizationId}/invitations/resend/${invitationId}`,
      endpointCallLocation: "useResendInvitation",
      serverType: "dashboard",
      mutateApis: [
        {
          url: `/api/organizations/${organizationId}/invitations`,
          component: "useGetPendingInvitations",
        },
      ],
    });
  };

  return {
    resendInvitation,
    isResendingInvitation: isLoading || isMutatingData,
    errorResendingInvitation: error,
  };
}
