//
// useGetProjectUsers.ts
//

import { useFetchData } from "@custom-hooks/fetch-data";
import { useGetFetchConditionBasedOnProject } from "@custom-hooks/fetch-data/hooks/useGetFetchConditionBasedOnProject";
import { FetchHookResult } from "@data-types/generic-hook-type";
import { ProjectUsers } from "@data-types/project-user-types";
import { ProjectStatus } from "@data-types/projects-types";

/**
 * Custom hook to fetch and manage user information for a specified project ID.
 *
 * @param {string} projectId - The unique identifier of the project for which to fetch user information.
 * @param {number} [errorRetryCount] - Max error retry count.
 *
 * @returns {FetchHookResult<ProjectUsers>} The result object with the list of users
 *   (or `undefined` if no users are available), UI state indicators, and a function to manage fetched data.
 */
export function useGetProjectUsers(
  projectId: string,
  errorRetryCount?: number
): FetchHookResult<ProjectUsers> {
  const fetchCondition = useGetFetchConditionBasedOnProject({
    id: projectId,
    projectStatus: ProjectStatus.RUNNING_AND_WORKING,
  });

  return useFetchData<ProjectUsers>({
    fetchCondition: fetchCondition && projectId !== "",
    source: "backend",
    swrKey: {
      url: `/api/projects/${projectId}/users`,
      component: "useGetProjectUsers",
    },
    options: {
      revalidateOnFocus: false,
      errorRetryCount,
    },
  });
}
