import { ContactDialog } from "@tw-components/support/ContactDialog";
import { GenericDialog, MessageType } from "@tw-components/ui/iso";
import { useState } from "react";
import { UpgradingInfraBanner } from "./UpgradingInfraBanner";

/**
 * Props for the UpgradingInfraDialog component.
 *
 * @property {boolean} isOpen - Indicates if the dialog is open.
 * @property {() => void} onClose - Function to call when the dialog is closed.
 * @property {"upgrade_to_pro" | "create_project" | "delete_project" | "generic"} type - Type of action being performed.
 */

type UpgradingInfraDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  type: "upgrade_to_pro" | "create_project" | "delete_project" | "generic" | "delete_organization";
};

/**
 * Component to display a dialog for upgrading infrastructure.
 *
 * @param {UpgradingInfraDialogProps} props - The props for the component.
 *
 * @returns {JSX.Element} The rendered component.
 */
export function UpgradingInfraDialog({
  isOpen,
  onClose,
  type
}: UpgradingInfraDialogProps): JSX.Element {
  const [showContactDialog, setShowContactDialog] = useState(false);
  const actionMessage = (() => {
    switch (type) {
      case "upgrade_to_pro":
        return "Upgrading to pro plan";
      case "create_project":
        return "Creating a new project";
      case "delete_project":
        return "Deleting a project";
      case "delete_organization":
        return "Deleting organization";
    }
  })();
  return <>
      <GenericDialog onClose={onClose} open={isOpen} title={"Infrastructure Upgrade in Progress"} body={type === "generic" ? <UpgradingInfraBanner onContactButtonClicked={() => {
      onClose();
      setShowContactDialog(true);
    }} /> : undefined} alertMessage={type !== "generic" ? {
      type: MessageType.Info,
      title: `We're upgrading our infrastructure to serve you better! ${actionMessage} is temporarily disabled. Please try again later.`
    } : undefined} size="xl" data-sentry-element="GenericDialog" data-sentry-source-file="UpgradingInfraDialog.tsx" />

      <ContactDialog isOpen={showContactDialog} onClose={() => setShowContactDialog(false)} source={"Upgrading Infrastructure Dialog"} data-sentry-element="ContactDialog" data-sentry-source-file="UpgradingInfraDialog.tsx" />
    </>;
}