import { FREE_MEMBERS_PER_ORGANIZATION_LIMIT } from "@lib/billing/constants";
import { useGetMembers } from "./useGetMembers";
import { useGetPendingInvitations } from "./useGetPendingInvitations";

/**
 * Hook to get the count of members and pending invitations for an organization.
 *
 * @param {string} params.organizationId - The ID of the organization.
 *
 * @returns {Object | undefined} An object containing:
 *   - `freeMembersAndInvitationsCount`: The count of free members and invitations, capped at the organization limit.
 *   - `totalMembersAndInvitationsCount: The total count of members and invitations.
 *   Returns `undefined` if data is not yet available.
 */
export function useGetMembersAndInvitationsCount({
  organizationId,
}: {
  organizationId: string;
}) {
  const { data: members } = useGetMembers({ organizationId });
  const { data: pendingInvitations } = useGetPendingInvitations({
    organizationId,
  });

  if (members && pendingInvitations) {
    const freeMembersAndInvitationsCount =
      members.length + pendingInvitations.length >
      FREE_MEMBERS_PER_ORGANIZATION_LIMIT
        ? FREE_MEMBERS_PER_ORGANIZATION_LIMIT
        : members.length + pendingInvitations.length;

    const totalMembersAndInvitationsCount =
      members.length + pendingInvitations.length;
    return {
      paidMembersAndInvitationsCount:
        totalMembersAndInvitationsCount - freeMembersAndInvitationsCount,
      freeMembersAndInvitationsCount,
      totalMembersAndInvitationsCount,
    };
  }

  return {
    paidMembersAndInvitationsCount: undefined,
    freeMembersAndInvitationsCount: undefined,
    totalMembersAndInvitationsCount: undefined,
  };
}
